import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import rdsLogo from "../images/rds-logo.png";
import blossomsLogo from "../images/blossoms-logo-2.png";
import { motion } from "framer-motion";
// data
import { AppConstants } from "../data/appConstants";

const NavbarItemList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const NavbarItem = styled.li`
  width: 1vw;
`;

const StyledNavLink = styled(NavHashLink)`
  margin: 1rem 0;
  padding: 0;
  width: 8vw;
  /* display: block; */
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1vw; // 1rem
`;

const StyledMotionP = styled(motion.p)`
  font-family: "HelveticaNeueBold";
`;

function Navbar() {
  return (
    // <Grid style={{position: 'fixed' }} container direction="row" justify="space-between" alignItems="center">

    <Grid
      style={{ position: "absolute" }}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={0}
    >
      <Grid item xs={6} sm={2} style={{ display: "flex" }}>
        <NavLink to="/">
          <img
            src={rdsLogo}
            style={{
              width: "10vw",
              maxWidth: "200px",
              height: "auto",
              maxHeight: "200px",
              minHeight: "100px",
              margin: 0,
              padding: "1rem",
              alignSelf: "center",
              objectFit: "contain",
            }}
          />
        </NavLink>
        <NavLink to="/">
          <img
            src={blossomsLogo}
            style={{
              width: "6vw",
              maxWidth: "200px",
              height: "auto",
              maxHeight: "200px",
              minHeight: "100px",
              margin: 0,
              padding: "1rem",
              alignSelf: "center",
              objectFit: "contain",
            }}
          />
        </NavLink>
      </Grid>
      <Grid item xs={false} sm={1}></Grid>
      <Grid item xs={false} sm={8}>
        <NavbarItemList>
          {AppConstants.navbar.nav_items.map((navItem) => (
            <StyledNavLink to={navItem.link_to} key={navItem.id}>
              <StyledMotionP
                whileHover={{
                  color: "rgba(0,188,228,1)",
                  transition: { duration: 1 },
                }}
              >
                {navItem.title_en}
              </StyledMotionP>
            </StyledNavLink>
          ))}
          <Grid item xs={false} sm={1}></Grid>

          {/* <StyledNavLink to="/">
            <StyledMotionP
              whileHover={{
                color: "rgba(0,188,228,1)",
                transition: { duration: 1 },
              }}
            >
              HOME
            </StyledMotionP>
          </StyledNavLink>
          <StyledNavLink to="/about-us">
            <StyledMotionP
              whileHover={{
                color: "rgba(0,188,228,1)",
                transition: { duration: 1 },
              }}
            >
              ABOUT US
            </StyledMotionP>
          </StyledNavLink>
          <StyledNavLink to="/solutions">
            <StyledMotionP
              whileHover={{
                color: "rgba(0,188,228,1)",
                transition: { duration: 1 },
              }}
            >
              SOLUTIONS
            </StyledMotionP>
          </StyledNavLink>
          <StyledNavLink to="/our-client">
            <StyledMotionP
              whileHover={{
                color: "rgba(0,188,228,1)",
                transition: { duration: 1 },
              }}
            >
              OUR CLIENT
            </StyledMotionP>
          </StyledNavLink>
          <StyledNavLink to="/showcase">
            <StyledMotionP
              whileHover={{
                color: "rgba(0,188,228,1)",
                transition: { duration: 1 },
              }}
            >
              SHOWCASE
            </StyledMotionP>
          </StyledNavLink>
          <StyledNavLink to="/contact-us">
            <StyledMotionP
              whileHover={{
                color: "rgba(0,188,228,1)",
                transition: { duration: 1 },
              }}
            >
              CONTACT US
            </StyledMotionP>
          </StyledNavLink> */}
        </NavbarItemList>
      </Grid>
    </Grid>
  );
}

export default Navbar;
