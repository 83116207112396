import { useMediaQuery, useTheme } from "@material-ui/core";
import { memo } from "react";

import { SectionProps } from "../types/types";
import MobileBannerSection from "./mobile/MobileBannerSection";
import WebBannerSection from "./web/WebBannerSection";

function BannerSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? (
    <MobileBannerSection />
  ) : (
    <WebBannerSection
      setActiveSection={setActiveSection}
      setLoadedSections={setLoadedSections}
      loadedSections={loadedSections}
    />
  );
}

export default memo(BannerSection);
