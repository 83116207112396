import { useTheme, useMediaQuery } from "@material-ui/core";

// components
import MobileAboutUsPage from "./mobile/MobileAboutUsPage";
import WebAboutUsPage from "./web/WebAboutUsPage";

function AboutUsPage() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? <MobileAboutUsPage /> : <WebAboutUsPage />;
}

export default AboutUsPage;
