import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { CategoryType } from "../../types/types";

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
`;

const StyledLi = styled.li`
  color: white;
  font-family: "HelveticaNeueBold";
  padding: 0.2rem 0.35rem;
`;

const selectedButtonStyle = {
  color: "rgba(0, 188, 228, 1)",
  fontSize: "1rem",
};

const unselectedButtonStyle = { color: "white", fontSize: "1rem" };

interface ShowcaseNavbarProps {
  category: string;
  setCategory: Dispatch<SetStateAction<CategoryType>>;
}

interface ShowcaseNavbarButtonProps {
  category: string;
  setCategory: Dispatch<SetStateAction<CategoryType>>;
  selectedCategory: CategoryType;
  selectedCategoryString: string;
}

function WebShowcaseNavbarDivider() {
  return (
    <StyledLi>
      <Button
        variant="text"
        size="large"
        style={unselectedButtonStyle}
        disabled
      >
        |
      </Button>
    </StyledLi>
  );
}

function WebShowcaseNavbarButton({
  category,
  setCategory,
  selectedCategory,
  selectedCategoryString,
}: ShowcaseNavbarButtonProps) {
  return (
    <StyledLi>
      <Button
        variant="text"
        size="large"
        style={
          category === selectedCategory
            ? selectedButtonStyle
            : unselectedButtonStyle
        }
        onClick={() => setCategory(selectedCategory)}
      >
        {selectedCategoryString}
      </Button>
    </StyledLi>
  );
}
function WebShowcaseNavbar({ category, setCategory }: ShowcaseNavbarProps) {
  return (
    <div style={{ width: "80%", minWidth: "360px", margin: "3rem auto" }}>
      <StyledUl>
        <WebShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="All"
          selectedCategoryString="ALL"
        />
        <WebShowcaseNavbarDivider />
        <WebShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Holistic_Campaign"
          selectedCategoryString="HOLISTIC CAMPAIGN"
        />
        <WebShowcaseNavbarDivider />
        <WebShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Video_Production"
          selectedCategoryString="VIDEO"
        />
        <WebShowcaseNavbarDivider />
        <WebShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Social"
          selectedCategoryString="SOCIAL"
        />
        <WebShowcaseNavbarDivider />
        <WebShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="China_Solution"
          selectedCategoryString="CHINA SOLUTION"
        />
        <WebShowcaseNavbarDivider />
        <WebShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Influencer"
          selectedCategoryString="INFLUENCER"
        />
      </StyledUl>
    </div>
  );
}

export default WebShowcaseNavbar;
