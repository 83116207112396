
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { IconButton } from "@material-ui/core";

// interface ButtonProps {
// }

function ScrollRightButton() {
  return (
    <motion.div
      style={{ display: "block", height: "auto" }}
      initial={{ x: 0, opacity: 1 }}
      animate={{ x: 50, opacity: 0 }}
      transition={{
        repeat: Infinity,
        duration: 1.5,
      }}
    >
      <IconButton>
        <ArrowForwardIcon fontSize="large" htmlColor="white" />
      </IconButton>
     
    </motion.div>
  );
}

export default ScrollRightButton;
