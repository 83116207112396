import { useState, useEffect } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

// components / pages
import LoadingPage from "./LoadingPage";
import MobileShowcasePage from "./mobile/MobileShowcasePage";
import WebShowcasePage from "./web/WebShowcasePage";

// types
import { IShowcase, IShowcaseOrder } from "../types/types";

// constants
import { herokuBaseUrl } from "../data/appConstants";
import { Helmet } from "react-helmet";
export interface ShowcasePageProps {
  showcases: IShowcase[];
}

function ShowcasePage() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const BASE_URL = herokuBaseUrl;
  const [showcaseData, setShowcaseData] = useState<Array<IShowcase>>([]);
  const [isFetching, setIsFetching] = useState(false);

  const reorder = (showcases: IShowcase[], orders: IShowcaseOrder[]) => {
    const sortedShowcases = orders.map(
      (order) =>
        showcases.filter(
          (showcase) => showcase.showcase_id === order.showcase_id
        )[0]
    );
    return sortedShowcases;
  };

  useEffect(() => {
    const fetchStrapi = async () => {
      setIsFetching(true);
      const fetchOption = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        // console.log("start fetching showcase data");
        const fetches = [
          fetch(`https://${BASE_URL}/showcases`, fetchOption),
          fetch(`https://${BASE_URL}/showcase-orders`, fetchOption),
        ];

        const [showcaseResponse, showcaseOrderResponse] = await Promise.all(
          fetches
        );

        const showcases = await showcaseResponse.json();
        const showcaseOrder = await showcaseOrderResponse.json();
        // console.log("showscases", showcases);
        // console.log("showcase order", showcaseOrder[0].showcase_ids);
        const orderedShowcases = reorder(
          showcases,
          showcaseOrder[0].showcase_ids
        );
        // console.log('ordered showcase', orderedShowcases);
        // console.log('showcaseData', showcaseData);
        setShowcaseData(orderedShowcases);
        // console.log("ordered showcases");
        // setData(content);
        // console.log(data);
        setIsFetching(false);
      } catch (err) {
        console.log(err);
        setIsFetching(false);
      }
    };
    fetchStrapi();
  }, [BASE_URL]);

  if (isFetching) {
    return <LoadingPage />;
  }

  return mobileView ? (
    <>
      <Helmet>
        <title>
          Digital marketing Creative Showcase | Showcase | RDS Digital
        </title>
        <meta
          name="description"
          content="Creative showcase : social content creation, influencer managemet, celebrity management, creative concept development, video production, online media plan & buy"
        />
      </Helmet>
      <MobileShowcasePage showcases={showcaseData} />
    </>
  ) : (
    <>
      <Helmet>
        <title>
          Digital marketing Creative Showcase | Showcase | RDS Digital
        </title>
        <meta
          name="description"
          content="Creative showcase : social content creation, influencer managemet, celebrity management, creative concept development, video production, online media plan & buy"
        />
      </Helmet>
      <WebShowcasePage showcases={showcaseData} />
    </>
  );
}

export default ShowcasePage;
