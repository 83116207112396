import { useState, useEffect } from "react";
import {
  StyledSection,
  SectionTitle,
  StyledH1,
  ShowcasePoster,
  ShowcaseBackdropTitle,
  ShowcaseBackdropContent,
} from "../../styles/styles";
import { Backdrop, Grid } from "@material-ui/core";

// components / pages
import LoadingPage from "../LoadingPage";
import ScrollTop from "../../components/ScrollTop";
import ShowcaseNavbar from "../../components/ShowcaseNavbar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// data
import { AppConstants } from "../../data/appConstants";

// interface
import { ShowcasePageProps } from "../ShowcasePage";
import { CategoryType, IShowcase } from "../../types/types";

function WebShowcaseBetaPage({ showcases }: ShowcasePageProps) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [curShowcase, setCurShowcase] = useState<IShowcase | null>(null);
  const [category, setCategory] = useState<CategoryType>("All");
  const [filteredShowcases, setFilteredShowcases] = useState<IShowcase[]>();

  console.log("web preview showcase page showcases", showcases);

  // filter showcases
  useEffect(() => {
    const filterShowcase = (showcases: IShowcase[]) => {
      if (!showcases) return;
      console.log("filter with category", category);
      return showcases.filter((showcase) =>
        showcase?.categories?.some((cat) => cat.category === category)
      );
    };

    if (category === "All") {
      setFilteredShowcases(showcases);
    } else {
      const filteredS = filterShowcase(showcases);
      setFilteredShowcases(filteredS || []);
    }

    console.log("filter showcase", filteredShowcases);
  }, [showcases, category, setCategory]);

  return (
    <>
      <ScrollTop />

      <StyledSection style={{ backgroundColor: "black" }}>
        <Navbar />
        <SectionTitle
          style={{
            paddingTop: "15vh",
            fontFamily: "HelveticaNeueCondenseBold",
          }}
        >
          {AppConstants.showcase.title_en}
        </SectionTitle>

        <ShowcaseNavbar category={category} setCategory={setCategory} />
        <Grid
          container
          justify="center"
          spacing={0}
          direction="row"
          style={{ margin: 0, paddingTop: "1rem" }}
        >
          {filteredShowcases?.map((showcase, idx) => (
            // cover image
            <div
              key={idx}
              style={{ position: "relative", width: "33.33%", height: "45vw" }}
              onClick={() => {
                console.log("set current showcase index", idx);
                setCurShowcase(showcase);
                setOpenBackdrop(true);
              }}
            >
              <ShowcasePoster
                initial={{ opacity: 1 }}
                whileHover={{
                  opacity: 0,
                  cursor: "pointer",
                  transition: { duration: 1 },
                }}
              >
                <StyledH1>{showcase?.client_name}</StyledH1>
              </ShowcasePoster>
              {showcase?.cover_media?.Media_source && (
                <img
                  src={showcase?.cover_media?.Media_source?.url}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="cover media"
                />
              )}
            </div>
          ))}
        </Grid>
      </StyledSection>

      <Footer />

      {/* start of backdrop */}
      <Backdrop
        style={{
          zIndex: 1000,
          overflowY: "scroll",
        }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <div
          style={{
            position: "absolute",
            width: "80%",
            top: "100px",
            backgroundColor: "#000",
          }}
        >
          <HighlightOffIcon
            style={{
              position: "absolute",
              fontSize: "3rem",
              color: "#fff",
              margin: "1rem",
              top: "1rem",
              right: "1rem",
            }}
          />

          <Grid container spacing={3} style={{ padding: "5rem 3rem" }}>
            {/* image banner */}
            {curShowcase?.banner_media?.Media_type === "image" && (
              <Grid item xs={12} sm={9}>
                <img
                  src={curShowcase?.banner_media?.Media_source?.url}
                  alt="banner"
                  style={{ height: "100%", width: "90%", objectFit: "contain" }}
                />
              </Grid>
            )}
            {/* video banner */}
            {curShowcase?.banner_media?.Media_type === "video" &&
              curShowcase?.banner_media?.Media_source?.url && (
                <Grid item xs={12} sm={9}>
                  <video
                    controls
                    width="80%"
                    src={
                      curShowcase?.banner_media?.Media_source?.url ||
                      // curShowcase?.banner_media?.Media_link ||
                      ""
                    }
                  />
                </Grid>
              )}

            {curShowcase?.banner_media?.Media_type === "video" &&
              curShowcase?.banner_media?.Media_link && (
                <Grid item xs={12} sm={9}>
                  <iframe
                    width="80%"
                    height="315"
                    src={curShowcase?.banner_media?.Media_link || ""}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Grid>
              )}

            {/* client info */}
            <Grid item xs={12} sm={3}>
              <ShowcaseBackdropTitle style={{ fontSize: "2rem" }}>
                CLIENT
              </ShowcaseBackdropTitle>
              <ShowcaseBackdropContent style={{ fontSize: "1.6rem" }}>
                {curShowcase?.client_name}
              </ShowcaseBackdropContent>
              <ShowcaseBackdropContent style={{ fontSize: "1.4rem" }}>
                {curShowcase?.title}
              </ShowcaseBackdropContent>

              {/* scope of service */}
              {curShowcase?.scopes?.length && (
                <div>
                  <ShowcaseBackdropTitle style={{ fontSize: "1.6rem" }}>
                    SCOPE OF SERVICES
                  </ShowcaseBackdropTitle>
                  {curShowcase?.scopes.map((scope, idx) => (
                    <ShowcaseBackdropContent
                      key={idx}
                      style={{ fontSize: "1.4rem" }}
                    >
                      {scope.scope_text}
                    </ShowcaseBackdropContent>
                  ))}
                </div>
              )}
            </Grid>
          </Grid>

          {/* content */}
          {curShowcase?.showcase_content?.map((content) => (
            <Grid container spacing={3} style={{ padding: "5rem 3rem" }}>
              <Grid item xs={12} sm={9}>
                {content?.Media?.map((media, idx) => {
                  switch (media.Media_type) {
                    case "video":
                      return media?.Media_link ? (
                        <iframe
                          width="560"
                          height="315"
                          src={media?.Media_link || ""}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        // <iframe
                        //   width="90%"
                        //   // height="auto"
                        //   title="video"
                        //   src={
                        //     media?.Media_link || media?.Media_source?.url || ""
                        //   }
                        // ></iframe>
                        <video
                          controls
                          width="90%"
                          src={
                            media?.Media_link || media?.Media_source?.url || ""
                          }
                        />
                      );
                    case "image":
                      return (
                        <img
                          key={idx}
                          src={media?.Media_source?.url}
                          alt={media?.Media_source?.name}
                          style={{
                            width: "90%",
                            // height: "100%",
                            margin: "10px",
                            objectFit: "contain",
                          }}
                        />
                      );
                    default:
                      return <p>no media</p>;
                  }
                })}
              </Grid>
              <Grid item xs={12} sm={3}>
                <ShowcaseBackdropContent style={{ fontSize: "1.6rem" }}>
                  {content.title}
                </ShowcaseBackdropContent>
              </Grid>
            </Grid>
          ))}
        </div>
      </Backdrop>
      {/* end of backdrop */}
    </>
  );
}

export default WebShowcaseBetaPage;
