import React, { useState, useEffect, memo } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// data
import { AppConstants, EmailJs } from "../data/appConstants";
import emailjs from "emailjs-com";
import MobileContactUsSection from "./mobile/MobileContactUsSection";
import WebContactUsSection from "./web/WebContactUsSection";
import { SectionProps } from "../types/types";

function ContactUsSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? (
    <MobileContactUsSection />
  ) : (
    <WebContactUsSection
      setActiveSection={setActiveSection}
      setLoadedSections={setLoadedSections}
      loadedSections={loadedSections}
    />
  );
}

export default memo(ContactUsSection);
