import { memo } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

// types
import { SectionProps } from "../types/types";

// components
import MobileClientSection from "./mobile/MobileClientSection";
import WebClientSection from "./web/WebClientSection";

// framer motion variants
export const clientLogoContainerVariants = {
  hidden: { opacity: 0, transition: { duration: 1 } },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.1,
    },
  },
};

export const clientLogoLineVariants = {
  hidden: { opacity: 0, transition: { duration: 1 } },
  visible: { opacity: 1, transition: { duration: 1 } },
};

function ClientSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div id="client-section">
      {mobileView ? (
        <MobileClientSection />
      ) : (
        <WebClientSection
          setActiveSection={setActiveSection}
          setLoadedSections={setLoadedSections}
          loadedSections={loadedSections}
        />
      )}
    </div>
  );
}

export default memo(ClientSection);
