
import { useTheme, useMediaQuery } from "@material-ui/core";
import MobileFooter from "./mobile/MobileFooter";
import WebFooter from "./web/WebFooter";

function Footer() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? <MobileFooter /> : <WebFooter />;
}

export default Footer;
