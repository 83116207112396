import { memo } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

// components / pages
import MobileShowcaseSection from "./mobile/MobileShowcaseSection";
import WebShowcaseSection from "./web/WebShowcaseSection";

// types
import { SectionProps } from "../types/types";


function ShowcaseSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? (
    <MobileShowcaseSection />
  ) : (
    <WebShowcaseSection
      setActiveSection={setActiveSection}
      setLoadedSections={setLoadedSections}
      loadedSections={loadedSections}
    />
  );
}

export default memo(ShowcaseSection);
