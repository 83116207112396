import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import {Link} from 'react-router-dom';

// logo
import linkinLogo from "../../images/linkin.png";
import facebookLogo from "../../images/facebook.png";
import youtubeLogo from "../../images/youtube.png";
// import googlePartnerLogo from '../../images/google-partner-logo.jpg';

// components
import LinkedLogo from '../LinkedLogo';

function WebFooter() {
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <Grid container>

        {/* text */}
        <Grid item xs={6}>
          <Box m={3}>
            <Typography
              variant="body2"
              align="left"
              style={{ marginLeft: "1rem", fontSize: "0.8rem" }}
            >
              COPYRIGHT {new Date().getFullYear()} 'RUBISON DIGITAL SOLUTIONS LIMITED' ALL RIGHTS
              RESERVED
            </Typography>
          </Box>
        </Grid>
     
        {/* google partner */}
        <Grid item xs={1}>
          <Box m={3}>
        
            {/* <LinkedLogo
              pathname="https://www.google.com/partners/agency?id=8265572971"
              logo={googlePartnerLogo}
              altname="google parter"
            /> */}
          </Box>
        </Grid>

        {/* social media */}   
        <Grid item xs={5}>
          <Box m={3}>
            <LinkedLogo
              pathname="https://www.linkedin.com/company/rdsdigitalagency"
              logo={linkinLogo}
              altname="linkin"
            />
            <LinkedLogo
              pathname="https://www.facebook.com/rdsdigitalagency"
              logo={facebookLogo}
              altname="facebook"
            />
              <LinkedLogo
              pathname="https://www.youtube.com/channel/UCPdNqcwRf9OlU0F8QgpTWmg"
              logo={youtubeLogo}
              altname="youtube"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default WebFooter;
