import React from "react";
import Particles from "react-tsparticles";

function ParticlesBackground() {
  const particlesInit = () => {
    console.log("particles init");
  };
  const particlesLoaded = () => {
    console.log("particles loaded");
  };
  return (
    <Particles
      id="tsparticles-1"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          // image: `url(${bg1})`,
          position: "50% 50%",
          repeat: "no-repeat",
          size: "cover",
        },
        fpsLimit: 30,
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.2,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 50,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 20,
          },
          opacity: {
            value: 0.2,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
  );
}

export default React.memo(ParticlesBackground);
