import React, { useState, useEffect, memo } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  IconButton,
  TextField,
  Snackbar,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  StyledSection,
  SectionTitle,
  StyledH1,
  StyledShowcaseContent,
  FadeInLetter,
  ClientLogo,
  ShowcasePoster,
  MobileStyledH1,
  MobileSectionTitle,
  MobileStyledSection,
} from "../../styles/styles";
import bg4 from "../../images/backgrounds/bg-4.png";
import bg10 from "../../images/backgrounds/bg-10.png";

// data
import { AppConstants, EmailJs } from "../../data/appConstants";
import emailjs from "emailjs-com";

import styled from "styled-components";

const StyleTypography = styled(Typography)`
  color: white;
  font-family: "HelveticaNeueBold";
  font-size: 1rem;
`;


const StyledTextField = styled(TextField)`
  background-color: #fff;
  width: 80%;
  border-radius: 10px;
`;

function MobileContactUsSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const sendEmail = async () => {
    const data = {
      name,
      email,
      contact,
      message,
    };
    try {
      const result = await emailjs.send(
        EmailJs.serviceId,
        EmailJs.templateId,
        data,
        EmailJs.userId
      );
      console.log(result);
      setName("");
      setEmail("");
      setContact("");
      setMessage("");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MobileStyledSection
      id="contact-us-section"
      style={{
        backgroundColor: "black",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: `url(${bg10})`,
                // height: "auto",
      }}
    >
      <MobileSectionTitle>
        {AppConstants.home.contact_us.en_title}
      </MobileSectionTitle>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ margin: "2rem 4rem" }}>
            <StyleTypography
              variant="h6"
              align="left"
              style={{
                fontFamily: "HelveticaNeueBold",

              }}
            >
              {AppConstants.home.contact_us.en_tagline_1}
            </StyleTypography>
            <StyleTypography
              variant="h6"
              align="left"
              style={{
                color: "rgba(0, 188, 228, 1)",
                fontFamily: "HelveticaNeueBold",

              }}
            >
              {AppConstants.home.contact_us.en_tagline_2}
            </StyleTypography>
            <br />
            <br />
            <Typography
              variant="h6"
              align="left"
              style={{
                color: "white",
                fontFamily: "HelveticaNeueBold",
                fontSize: "0.8rem",
              }}
            >
              {AppConstants.home.contact_us.en_phone}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              style={{
                color: "white",
                fontFamily: "HelveticaNeueBold",
                fontSize: "0.8rem",
              }}
            >
              {AppConstants.home.contact_us.en_email}
            </Typography>
            <br />
            <br />
            <Typography
              variant="h6"
              align="left"
              style={{
                color: "white",
                fontFamily: "HelveticaNeueBold",
                fontSize: "0.8rem",
              }}
            >
              {AppConstants.home.contact_us.en_address_1}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              style={{
                color: "white",
                fontFamily: "HelveticaNeueBold",
                fontSize: "0.8rem",
              }}
            >
              {AppConstants.home.contact_us.en_address_2}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              style={{
                color: "white",
                fontFamily: "HelveticaNeueBold",
                fontSize: "0.8rem",
              }}
            >
              {AppConstants.home.contact_us.en_address_3}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={12} style={{ margin: "1rem" }}>
            <form>
              {/* name */}
              <Box m={1}>
                <StyledTextField
                  id="name"
                  placeholder="NAME"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>

              {/* email */}
              <Box m={1}>
                <StyledTextField
                  id="email"
                  placeholder="EMAIL"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>

              {/* contact */}
              <Box m={1}>
                <StyledTextField
                  id="contact"
                  placeholder="MOBILE"
                  variant="outlined"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </Box>

              {/* message */}
              <Box m={1}>
                <StyledTextField
                  id="message"
                  placeholder="MESSAGE"
                  variant="outlined"
                  multiline
                  rows={12}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Box>
              <Box m={3}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#fff",
                    width: "50vw",
                    borderRadius: "10px",
                  }}
                  disabled={!name || !contact || !message}
                  onClick={() => sendEmail()}
                >
                  <Typography variant="body1" color="primary">
                    Send
                  </Typography>
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </MobileStyledSection>
  );
}

export default MobileContactUsSection;
