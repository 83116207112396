import React from "react";
import logo from "../images/svgs/RDS_logo-02.svg";
import logoSvg from "../images/svgs/RDS_logo-01.svg";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, pathLength: 0 },
  show: {
    opacity: 0.8,
    pathLength: 1,
    transition: { duration: 3, ease: "easeOut", yoyo: Infinity },
  },
};

function LoadingLogo() {
  return (
    <div>
      <motion.svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1163.41 754.41"
      >
        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="#000"
          strokeWidth="0.25mm"
          fill="none"
          style={{
            stroke: "#fff",
            strokeWidth: "1",
            // fill: "red",
            // fillOpacity: "0.5",
          }}
        >
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M1026.51,296.36a23.09,23.09,0,1,0-24.24,23.13V520.31H136.9V610H352.21a20.87,20.87,0,1,0-.77-5.35H142.21v-79h865.37V319.18A23.2,23.2,0,0,0,1026.51,296.36ZM372.45,588.42a16.28,16.28,0,1,1-16.17,16.28A16.25,16.25,0,0,1,372.45,588.42Zm631-274.16a17.91,17.91,0,1,1,17.77-17.9A17.86,17.86,0,0,1,1003.43,314.26Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M458,585.9l4.13,27.77h.1l4.39-27.77h7.2l4.39,27.77h.1l4.13-27.77h7.21l-6.79,37.55h-8.41l-4.18-27.24h-.1L466,623.45h-8.4l-6.8-37.55Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M506.06,585.9l4.13,27.77h.11l4.38-27.77h7.21l4.38,27.77h.11l4.12-27.77h7.21l-6.79,37.55h-8.41l-4.17-27.24h-.11l-4.18,27.24h-8.41l-6.78-37.55Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M554.11,585.9l4.12,27.77h.11l4.38-27.77h7.21l4.39,27.77h.1l4.13-27.77h7.2L579,623.45h-8.41l-4.18-27.24h-.11l-4.17,27.24h-8.41L546.9,585.9Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M600.38,623.45h-6.9v-8.2h6.9Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M628.05,585.9q4.69,0,7.31,2.4c1.74,1.59,2.61,4,2.61,7.27a11.42,11.42,0,0,1-1.51,6.19,7,7,0,0,1-4.7,3.07v.11a6.75,6.75,0,0,1,4.12,1.84,7.78,7.78,0,0,1,1.62,4.57c.07.71.12,1.47.16,2.29s.07,1.74.1,2.76c.08,2,.18,3.52.31,4.58a3,3,0,0,0,1.37,2.15v.32h-8.15a4.25,4.25,0,0,1-.74-1.82,20.27,20.27,0,0,1-.2-2.13l-.21-7.2a6,6,0,0,0-1.1-3.47,4,4,0,0,0-3.34-1.26h-4v15.88h-7.52V585.9Zm-3.24,16.41A6.12,6.12,0,0,0,629,601c1-.86,1.46-2.31,1.46-4.34q0-5.2-5.22-5.21h-3.55v10.84Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M664.24,585.9a13.36,13.36,0,0,1,6.32,1.29,9.18,9.18,0,0,1,3.73,3.68,16.4,16.4,0,0,1,1.8,5.76,60.08,60.08,0,0,1-.13,16.12,16.63,16.63,0,0,1-2.06,6,9.07,9.07,0,0,1-3.92,3.52,14.55,14.55,0,0,1-6.21,1.16H651.91V585.9Zm-1.46,32a6.47,6.47,0,0,0,3.18-.68,4.71,4.71,0,0,0,1.91-2.26,13.49,13.49,0,0,0,.94-4.16c.16-1.71.23-3.85.23-6.41,0-2.14-.07-4-.2-5.63a14.15,14.15,0,0,0-.87-4,5,5,0,0,0-1.93-2.42,6.32,6.32,0,0,0-3.42-.82h-3.18v26.4Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M695.94,612.83a13.5,13.5,0,0,0,.2,2.39,4.1,4.1,0,0,0,.79,1.81,3.81,3.81,0,0,0,1.54,1.16,6.09,6.09,0,0,0,2.43.42,4.78,4.78,0,0,0,3.1-1.13,4.23,4.23,0,0,0,1.39-3.5,6.48,6.48,0,0,0-.34-2.18,4.37,4.37,0,0,0-1.12-1.65,8,8,0,0,0-2.07-1.35,29.27,29.27,0,0,0-3.16-1.23,27.48,27.48,0,0,1-4.33-1.84,11.9,11.9,0,0,1-3.06-2.34,8.5,8.5,0,0,1-1.8-3.11,13.2,13.2,0,0,1-.57-4.07c0-3.72,1-6.49,3.08-8.31s4.87-2.73,8.46-2.73a18.18,18.18,0,0,1,4.62.55,9.52,9.52,0,0,1,3.65,1.79,8.35,8.35,0,0,1,2.41,3.15,11.27,11.27,0,0,1,.86,4.6v1h-7.21a7.58,7.58,0,0,0-.94-4.13,3.45,3.45,0,0,0-3.13-1.44,5.15,5.15,0,0,0-2.09.37,3.53,3.53,0,0,0-1.33,1,3.39,3.39,0,0,0-.68,1.4,7.27,7.27,0,0,0-.18,1.63,5.42,5.42,0,0,0,.73,2.91,6.59,6.59,0,0,0,3.13,2.19l5.8,2.52a18,18,0,0,1,3.5,2,9.57,9.57,0,0,1,2.16,2.21,7.46,7.46,0,0,1,1.13,2.6,14.66,14.66,0,0,1,.31,3.16q0,5.94-3.42,8.65t-9.53,2.7c-4.25,0-7.29-.92-9.11-2.78s-2.74-4.52-2.74-8v-1.53h7.52Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M732.44,623.45h-6.89v-8.2h6.89Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M761.18,592.61a3.59,3.59,0,0,0-3.52-1.87,3.49,3.49,0,0,0-2.32.76A5.12,5.12,0,0,0,753.9,594a21.17,21.17,0,0,0-.73,4.48q-.21,2.74-.21,6.75a66.52,66.52,0,0,0,.29,6.89,15.28,15.28,0,0,0,.89,4.07,3.73,3.73,0,0,0,1.51,2,4.36,4.36,0,0,0,2.11.5,5.17,5.17,0,0,0,1.86-.34,3.12,3.12,0,0,0,1.49-1.37,9.28,9.28,0,0,0,1-2.89,26.94,26.94,0,0,0,.37-4.92H770a33.59,33.59,0,0,1-.48,5.79,13.15,13.15,0,0,1-1.74,4.78,8.78,8.78,0,0,1-3.6,3.21,13.66,13.66,0,0,1-6,1.15,14.17,14.17,0,0,1-6.74-1.36,9.27,9.27,0,0,1-3.84-3.95,17.51,17.51,0,0,1-1.72-6.15,79.6,79.6,0,0,1,0-15.74,17.74,17.74,0,0,1,1.72-6.19,9.79,9.79,0,0,1,3.84-4,13.54,13.54,0,0,1,6.74-1.44,13,13,0,0,1,6.35,1.31,9,9,0,0,1,3.47,3.34,11.66,11.66,0,0,1,1.43,4.37,34.49,34.49,0,0,1,.29,4.39h-7.52A13.6,13.6,0,0,0,761.18,592.61Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M782.23,596.82a17.53,17.53,0,0,1,1.72-6.19,9.72,9.72,0,0,1,3.84-4,16.49,16.49,0,0,1,13.48,0,9.79,9.79,0,0,1,3.84,4,18,18,0,0,1,1.72,6.19,79.6,79.6,0,0,1,0,15.74,17.73,17.73,0,0,1-1.72,6.15,9.27,9.27,0,0,1-3.84,3.95,17.38,17.38,0,0,1-13.48,0,9.21,9.21,0,0,1-3.84-3.95,17.3,17.3,0,0,1-1.72-6.15,79.6,79.6,0,0,1,0-15.74Zm7.31,14.3a18.23,18.23,0,0,0,.73,4.31,4.85,4.85,0,0,0,1.57,2.42,5.14,5.14,0,0,0,5.38,0,4.77,4.77,0,0,0,1.56-2.42,18.17,18.17,0,0,0,.74-4.31q.18-2.65.18-6.45t-.18-6.41a18.46,18.46,0,0,0-.74-4.31,4.74,4.74,0,0,0-1.56-2.45,5.14,5.14,0,0,0-5.38,0,4.81,4.81,0,0,0-1.57,2.45,18.51,18.51,0,0,0-.73,4.31q-.18,2.63-.18,6.41T789.54,611.12Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M831.34,585.9l5.33,26.5h.1l5.38-26.5h11.12v37.55h-6.89v-30h-.1l-6.63,30H833.8l-6.64-30h-.1v30h-6.89V585.9Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M874.11,623.45h-6.89v-8.2h6.89Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M895.42,585.9v14.36h8.77V585.9h7.52v37.55h-7.52V606.78h-8.77v16.67H887.9V585.9Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M932.81,600.94h.1l9.51-15h8.14L940.43,602l11.28,21.46h-8.46L935.58,608l-2.77,4.26v11.15h-7.52V585.9h7.52Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M515.66,264.5V197.79h52.78a29.48,29.48,0,0,1,6.6.92c2.57.61,5.23,1.22,8,1.83a75.83,75.83,0,0,1,7.51,2.06q3.42,1.16,5.23,1.6a99.34,99.34,0,0,1,28.67,17,101,101,0,0,1,20.25,23.37,106.66,106.66,0,0,1,9.75,20h71.12c-.12-.54-.21-1.11-.33-1.65a235.55,235.55,0,0,0-12.06-36.19c-1.21-2.75-2.5-5.34-3.87-7.79s-2.66-4.74-3.86-6.88a140.54,140.54,0,0,0-17.52-25A165.89,165.89,0,0,0,666,166.17a173.09,173.09,0,0,0-23.66-15.8,150.73,150.73,0,0,0-22.52-10.31c-1.21-.3-3.8-1.07-7.73-2.29s-8.35-2.44-13.2-3.67-9.71-2.36-14.56-3.44a71,71,0,0,0-11.38-1.6H447V264.5Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M654.91,332.22a95.83,95.83,0,0,1-10.48,22,98.48,98.48,0,0,1-22.3,24.29Q608.48,389.32,588,395.27c-1.82.62-4.71,1.38-8.65,2.29a45.32,45.32,0,0,1-10,1.38H515.66V332.22H447V467.67H573a54.62,54.62,0,0,0,8.19-.92q5.46-.93,10.92-2.06t10.24-2.29c3.19-.77,5.53-1.3,7-1.61q31.41-10.08,53.92-28A172.53,172.53,0,0,0,700.4,393,168.8,168.8,0,0,0,722,346.7c1.41-4.87,2.54-9.69,3.53-14.48Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M488.49,297.45a18.31,18.31,0,0,1-3.05,10,18.53,18.53,0,0,1-3.95,4.24,17.89,17.89,0,0,1-5.75,3l-.75.17-1.09.25-1.16.22a6.33,6.33,0,0,1-.87.09H458.44V279.3h13.43a6.34,6.34,0,0,1,1.21.17c.51.11,1,.24,1.55.37s1,.26,1.41.39l.82.24a16.46,16.46,0,0,1,2.4,1.1,19,19,0,0,1,2.52,1.68,17.29,17.29,0,0,1,2.32,2.22,15,15,0,0,1,1.87,2.66c.13.23.27.47.41.74a8,8,0,0,1,.41.83,25.57,25.57,0,0,1,1.29,3.85A17.36,17.36,0,0,1,488.49,297.45Zm-7.32-.29a10.2,10.2,0,0,0-.41-2.76,11.44,11.44,0,0,0-1.26-2.8,11,11,0,0,0-2.16-2.49,10.62,10.62,0,0,0-3-1.81,4.38,4.38,0,0,1-.56-.17c-.24-.08-.51-.15-.8-.22l-.85-.19a2.8,2.8,0,0,0-.7-.1h-5.62V308h5.72a5.24,5.24,0,0,0,1.06-.14c.42-.1.73-.18.93-.25a10.44,10.44,0,0,0,3.63-1.78,10.71,10.71,0,0,0,2.38-2.58,10.21,10.21,0,0,0,1.3-3A12.82,12.82,0,0,0,481.17,297.16Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M512.88,315.36h-7.32v-36h7.32Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M557.86,315.36h-9.94a2.71,2.71,0,0,1-.43-.05l-.58-.09-1.7-.29a9.16,9.16,0,0,1-1.79-.54,17.79,17.79,0,0,1-5.48-3.12,19.22,19.22,0,0,1-3.75-4.28,17.93,17.93,0,0,1-1.31-16.74,19,19,0,0,1,12-10.31l.9-.23.94-.21.92-.13.68-.07h2.28v7.32h-2.23a7.16,7.16,0,0,0-1.28.24,2.07,2.07,0,0,1-.61.15,7.86,7.86,0,0,0-1.82.68,12,12,0,0,0-1.82,1.18,11.67,11.67,0,0,0-1.62,1.53,7.91,7.91,0,0,0-1.19,1.78,1.14,1.14,0,0,0-.17.27,2.39,2.39,0,0,1-.17.37c-.35,1-.6,1.75-.75,2.32a9.24,9.24,0,0,0-.22,2.17,11.9,11.9,0,0,0,.37,2.83,10.58,10.58,0,0,0,1.14,2.8,10.28,10.28,0,0,0,2,2.49,10,10,0,0,0,3.11,1.88l.5.17c.25.08.51.17.8.25l.85.22a3.27,3.27,0,0,0,.66.09h2.37v-7h-4.12v-7.33h11.44Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M633.21,286.62H621.63v28.74h-7.32V286.67H602.63v-7.32h30.58Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M584.29,315.36H577v-36h7.32Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M676.43,315.41h-8.09l-2.62-5.56H653.41l-2.62,5.56h-8l16.86-36.11Zm-14.1-12.93-2.72-5.86-2.76,5.86Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M714.25,315.36H688.9v-36h7.32V308h18Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M364.79,322q27.79-18.33,38.87-42.17t11.08-46.28a107.52,107.52,0,0,0-11.1-46.28,102.12,102.12,0,0,0-17.74-24.75A91.86,91.86,0,0,0,365.79,146a180.57,180.57,0,0,0-21.05-10.54c-1.57-.61-12.45-3.59-15.74-4.35s-14.8-2.53-16.68-2.53H185.94v20.85L261.2,197.3h49.24a32.63,32.63,0,0,1,4.46.69,36.29,36.29,0,0,1,5.88,1.59q13.14,5.46,18.08,15a37.77,37.77,0,0,1,0,37.35q-4.92,9.56-18.08,14.56a50.21,50.21,0,0,1-5.88,1.82,24.09,24.09,0,0,1-4.46.91H256.88V248.36l-70.94-45.15v264h70.94V338h37.23L354,467.67h77.24Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M840.91,330.89l60.73-38.64c-2.15-1.4-12.73-6.82-15.55-8a65.74,65.74,0,0,0-5.88-3,65.41,65.41,0,0,1-5.87-3q-9.87-4.58-19.73-9.63l-19.74-10.08a39.69,39.69,0,0,1-3.75-2.06c-1.26-.76-2.51-1.44-3.76-2.06q-10.82-5.49-15.51-14c-3.13-5.64-4.69-10.77-4.69-15.35q0-9.61,3.75-15.81a25.5,25.5,0,0,1,9.64-9.39,40.51,40.51,0,0,1,13.15-4.36A93.75,93.75,0,0,1,848,194.39,75.34,75.34,0,0,1,862.6,196a59.34,59.34,0,0,1,15.5,5.5,67.23,67.23,0,0,1,14.09,9.86A47.48,47.48,0,0,1,903,226l60.14-29.33s-5.49-9.46-6.11-11a100,100,0,0,0-19-23.14,133.16,133.16,0,0,0-23.26-16.73,118.57,118.57,0,0,0-24.66-10.53,149.36,149.36,0,0,0-23.26-5.05c-1.57,0-7.66-1.17-22.08-1.37a166.56,166.56,0,0,0-22.55,1.6,122.06,122.06,0,0,0-22.55,5.27,93.07,93.07,0,0,0-14.8,6.42,127.08,127.08,0,0,0-15.27,9.62,118,118,0,0,0-14.1,12.15,63.85,63.85,0,0,0-10.8,14.43,69,69,0,0,0-3.52,6.87c-1.11,2.46-2,4.44-2.59,6a24.43,24.43,0,0,0-1.41,4.81c-.31,1.68-2,11.23-2.35,15.82s-.47,7.18-.47,7.78a119.81,119.81,0,0,0,2.12,22.23,82.79,82.79,0,0,0,7.52,22,85.22,85.22,0,0,0,14.8,20.63,93.78,93.78,0,0,0,24,17.64q4.69,2.3,9.64,4.81c3.28,1.68,13.07,6.27,15.27,7.33s3.91,1.91,5.16,2.52c3.13,1.53,6.82,3.37,11,5.5S838.31,329.65,840.91,330.89Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M950.22,353.63c-1.41-7.48-5.32-20.59-12.53-30.52l-58.78,37.41c1.3,3.27,1.07,6.19,1.07,9.83q0,9.63-3.76,15.59a26,26,0,0,1-9.63,9.16,39.94,39.94,0,0,1-13.16,4.35,94.4,94.4,0,0,1-14.8,1.15,70.81,70.81,0,0,1-14.32-1.6A64.2,64.2,0,0,1,809,393.73a59.82,59.82,0,0,1-14.1-9.63,47.07,47.07,0,0,1-10.8-14.66h-1.42L724,398.31c.94,1.83,2,3.74,3,5.73s2.12,3.74,3.05,5.27a96.13,96.13,0,0,0,19,23.6,123.86,123.86,0,0,0,23.26,16.49,126.29,126.29,0,0,0,24.67,10.32,150.07,150.07,0,0,0,23.25,5,49.92,49.92,0,0,0,5.87.69l7.05.45q3.53.23,9.16.23c3.13,0,6.81-.16,11-.45s8.38-.69,12.45-1.15a93.7,93.7,0,0,0,11.75-2.07q5.64-1.36,9.86-2.74,18.33-6.89,30.78-16.05a89.25,89.25,0,0,0,20-19.7A70.64,70.64,0,0,0,949,401.29a96.93,96.93,0,0,0,3.29-25.43A120,120,0,0,0,950.22,353.63Z"
          />
        </g>
      </motion.svg>

      {/* <motion.svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 753.98 519.53"
      >
     
        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="#000"
          strokeWidth="0.25mm"
          fill="none"
          style={{
            stroke: "#fff",
            strokeWidth: "1",
            // fill: "black",
            // fillOpacity: "0.5",
          }}
        >
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M247.69,227.56q14.87-10,20.79-23.12a61.31,61.31,0,0,0,5.93-25.39,60.27,60.27,0,0,0-5.94-25.38A56,56,0,0,0,259,140.1a49.63,49.63,0,0,0-10.75-9A97.31,97.31,0,0,0,237,125.27c-.84-.33-2-.71-3.52-1.13s-3.14-.83-4.9-1.25-3.43-.76-5-1a26.38,26.38,0,0,0-3.9-.38H152v12.67l38,25h28.64a17.38,17.38,0,0,1,2.38.38,18.73,18.73,0,0,1,3.15.87q7,3,9.67,8.24a21.18,21.18,0,0,1,0,20.48q-2.64,5.24-9.67,8a28.44,28.44,0,0,1-3.15,1,13,13,0,0,1-2.38.49H190V185L152,160V307.22H190V236.35h19.91l32,71.13h41.32Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M462.33,195.68A46.72,46.72,0,0,0,470.25,207a50.28,50.28,0,0,0,12.81,9.67q2.52,1.26,5.16,2.64t4.9,2.64a27.73,27.73,0,0,1,3.27,1.38l2.76,1.38q2.5,1.26,5.9,3c2.27,1.17,4.4,2.31,6.41,3.39l2.37,1.29,38.27-18.05q-1.87-1.26-3.94-2.46t-4.78-2.64c-1.85-.92-3.52-1.72-5-2.39-.84-.5-1.88-1-3.14-1.63s-2.3-1.13-3.14-1.63q-5.28-2.52-10.55-5.28L511,192.79c-.67-.34-1.34-.71-2-1.13a22.6,22.6,0,0,0-2-1.13,19,19,0,0,1-8.29-7.67,18,18,0,0,1-2.52-8.42,16.81,16.81,0,0,1,2-8.67,13.78,13.78,0,0,1,5.16-5.15,21.17,21.17,0,0,1,7-2.39,49.17,49.17,0,0,1,7.67-.63,39.45,39.45,0,0,1,7.79.88,31.12,31.12,0,0,1,8.29,3,36.26,36.26,0,0,1,7.54,5.4,26,26,0,0,1,5.78,8l32.17-16.08c-.5-.84-1.09-1.84-1.76-3a26.31,26.31,0,0,1-1.51-3,54.14,54.14,0,0,0-10.18-12.69A71.06,71.06,0,0,0,553.68,131a62.76,62.76,0,0,0-13.19-5.77,77.16,77.16,0,0,0-12.44-2.77c-.84,0-1.93,0-3.27-.13l-3.89-.25c-1.26-.08-2.81-.2-4.65-.38a87.85,87.85,0,0,0-12.06.88,65.4,65.4,0,0,0-12.07,2.89A49.84,49.84,0,0,0,484.2,129a67.36,67.36,0,0,0-8.17,5.28,62.59,62.59,0,0,0-7.54,6.66,34.77,34.77,0,0,0-5.78,7.92,40.15,40.15,0,0,0-1.89,3.77c-.59,1.34-1,2.43-1.38,3.27a12.82,12.82,0,0,0-.75,2.63,16.27,16.27,0,0,1-.51,2.14,61.19,61.19,0,0,0-.75,6.53c-.17,2.52-.25,3.94-.25,4.28a67.59,67.59,0,0,0,1.13,12.18A46.57,46.57,0,0,0,462.33,195.68Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M568.76,232.87c-.17-.36-.37-.71-.55-1.07l-34.46,16.25a14.74,14.74,0,0,1,1.34,6.06,16.09,16.09,0,0,1-2,8.54,14.11,14.11,0,0,1-5.16,5,21.25,21.25,0,0,1-7,2.39,49.58,49.58,0,0,1-7.92.63,37.45,37.45,0,0,1-7.66-.88,34.09,34.09,0,0,1-8.17-2.89,32.14,32.14,0,0,1-7.54-5.28,26,26,0,0,1-5.78-8h-.76l-31.41,15.83c.5,1,1,2.05,1.63,3.14s1.14,2.05,1.64,2.89a52,52,0,0,0,10.18,12.94,66.44,66.44,0,0,0,25.63,14.7,78,78,0,0,0,12.44,2.77,27.56,27.56,0,0,0,3.14.38l3.77.25c1.26.08,2.89.12,4.9.12,1.67,0,3.65-.08,5.91-.25s4.48-.38,6.66-.63a47.8,47.8,0,0,0,6.28-1.13q3-.75,5.28-1.51a67.36,67.36,0,0,0,16.46-8.79,48.22,48.22,0,0,0,10.68-10.81A39.07,39.07,0,0,0,572,271.07a54.47,54.47,0,0,0,1.76-13.95,67.78,67.78,0,0,0-1.13-12.19A49.49,49.49,0,0,0,568.76,232.87Z"
          />
      
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M334.57,196.25V159.52h29.26a16.74,16.74,0,0,1,3.66.5c1.43.34,2.9.68,4.41,1a42.71,42.71,0,0,1,4.17,1.14c1.26.42,2.22.71,2.9.88a55.22,55.22,0,0,1,27.11,22.2,58.07,58.07,0,0,1,5.41,11h39.43c-.07-.3-.12-.62-.19-.91a128.39,128.39,0,0,0-6.68-19.93c-.67-1.51-1.39-2.94-2.14-4.29l-2.15-3.78a77,77,0,0,0-9.71-13.75,91.3,91.3,0,0,0-12.11-11.48,96.11,96.11,0,0,0-13.12-8.7,83.72,83.72,0,0,0-12.48-5.68q-1-.24-4.29-1.26t-7.32-2q-4-1-8.07-1.89a31.42,31.42,0,0,0-6.3-.88H296.48v74.57Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M411.77,233.53A52.66,52.66,0,0,1,406,245.66a53.12,53.12,0,0,1-31.28,22.58q-1.51.51-4.79,1.26a25.15,25.15,0,0,1-5.55.76H334.57V233.53H296.48V308.1h69.88a30.18,30.18,0,0,0,4.54-.51c2-.34,4-.71,6.05-1.13s3.91-.85,5.68-1.26,3.06-.72,3.91-.89a94.09,94.09,0,0,0,29.89-15.38A95.64,95.64,0,0,0,437,267a92.65,92.65,0,0,0,12-25.48c.78-2.68,1.41-5.33,2-8Z"
          />

          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M319.51,214.39a9.69,9.69,0,0,1-.42,2.76,10,10,0,0,1-1.27,2.72,10.23,10.23,0,0,1-2.19,2.33,9.91,9.91,0,0,1-3.19,1.64l-.41.1-.61.13c-.21.05-.43.09-.64.12a3,3,0,0,1-.49.06h-7.44V204.39h7.44a2.92,2.92,0,0,1,.67.1l.86.2.78.21.46.14a8.37,8.37,0,0,1,1.33.6,10.11,10.11,0,0,1,2.69,2.15,8.12,8.12,0,0,1,1,1.47c.07.12.15.25.23.4a3.94,3.94,0,0,1,.23.46,13.52,13.52,0,0,1,.71,2.12A9.45,9.45,0,0,1,319.51,214.39Zm-4.06-.16a5.2,5.2,0,0,0-.23-1.52,5.79,5.79,0,0,0-.7-1.55,5.82,5.82,0,0,0-2.88-2.36l-.31-.09-.45-.13-.47-.1a1.55,1.55,0,0,0-.39-.06h-3.11v11.8h3.17a2.92,2.92,0,0,0,.59-.08l.51-.14a5.62,5.62,0,0,0,3.33-2.4,5.75,5.75,0,0,0,.73-1.65A7.29,7.29,0,0,0,315.45,214.23Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M332.7,224.25h-4.06V204.42h4.06Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M356.5,224.25H351l-.25,0-.32-.05-.94-.16a6,6,0,0,1-1-.3,9.75,9.75,0,0,1-3-1.72,10.87,10.87,0,0,1-2.08-2.35,9.74,9.74,0,0,1-1.2-2.66,9.56,9.56,0,0,1-.35-2.62,9.45,9.45,0,0,1,.83-3.94,10.61,10.61,0,0,1,6.64-5.68l.5-.12.52-.12.51-.07.38,0h1.26v4h-1.23a3.24,3.24,0,0,0-.71.14,1.39,1.39,0,0,1-.34.08,4.16,4.16,0,0,0-1,.37,6.33,6.33,0,0,0-1,.65,6.57,6.57,0,0,0-.91.84,4.5,4.5,0,0,0-.65,1,.56.56,0,0,0-.1.15,1.2,1.2,0,0,1-.09.21,13.05,13.05,0,0,0-.42,1.27,4.93,4.93,0,0,0-.12,1.2,6.53,6.53,0,0,0,.2,1.55,6.31,6.31,0,0,0,.63,1.55,6,6,0,0,0,1.13,1.37,5.5,5.5,0,0,0,1.72,1l.28.1.45.13.47.12a1.48,1.48,0,0,0,.36.06h1.32v-3.84h-2.29v-4h6.34Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M369.69,224.25h-4.05V204.42h4.05Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M395.78,208.42h-6.42v15.83H385.3v-15.8h-6.47v-4h16.95Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M423.05,224.27h-4.48l-1.46-3.06h-6.82l-1.45,3.06h-4.46l9.35-19.88Zm-7.82-7.12-1.5-3.22-1.53,3.22Z"
          />
          <motion.path
            variants={variants}
            initial="hidden"
            animate="show"
            d="M445.7,224.25H431.65V204.42h4.05v15.8h10Z"
          />
        </g>
      </motion.svg>
      */}
    </div>
  );
}

export default LoadingLogo;
