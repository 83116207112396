import { useState, useEffect } from "react";
import {
  SectionTitle,
  ShowcasePoster,
  MobileStyledSection,
  ShowcaseBackdropTitle,
  ShowcaseBackdropContent,
  ShowcasePosterTitle,
} from "../../styles/styles";
import { Backdrop, Grid } from "@material-ui/core";

// components / pages
import MobileNavbar from "../../components/MobileNavbar";
import ScrollTop from "../../components/ScrollTop";
import ShowcaseNavbar from "../../components/ShowcaseNavbar";
import Footer from "../../components/Footer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// data
import { AppConstants } from "../../data/appConstants";

// interface
import { ShowcasePageProps } from "../ShowcasePage";
import { CategoryType, IShowcase } from "../../types/types";

// animation
import { InView } from "react-intersection-observer";
import {
  showcaseVariants,
  showcasePosterTitleVariants,
} from "../../animations/animations";

function MobileShowcaseBetaPage({ showcases }: ShowcasePageProps) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [curShowcase, setCurShowcase] = useState<IShowcase | null>(null);
  const [category, setCategory] = useState<CategoryType>("All");
  const [filteredShowcases, setFilteredShowcases] = useState<IShowcase[]>();

  console.log("mobile preview showcase page showcases", showcases);

  // filter showcases
  useEffect(() => {
    const filterShowcase = (showcases: IShowcase[]) => {
      if (!showcases) return;
      console.log("filter with category", category);
      return showcases.filter((showcase) =>
        showcase?.categories?.some((cat) => cat.category === category)
      );
    };

    if (category === "All") {
      setFilteredShowcases(showcases);
    } else {
      const filteredS = filterShowcase(showcases);
      setFilteredShowcases(filteredS || []);
    }

    console.log("filtered showcase", filteredShowcases);
  }, [showcases, category, setCategory]);

  return (
    <>
      <ScrollTop />

      <MobileStyledSection
        style={{ backgroundColor: "black", height: "100vh" }}
      >
        <MobileNavbar />
        <SectionTitle
          style={{
            paddingTop: "15vh",
            fontFamily: "HelveticaNeueCondenseBold",
          }}
        >
          {AppConstants.showcase.title_en}
        </SectionTitle>
        <ShowcaseNavbar category={category} setCategory={setCategory} />
        <Grid
          container
          justify="center"
          spacing={0}
          direction="row"
          style={{ margin: 0, paddingTop: "1rem" }}
        >
          {filteredShowcases?.map((showcase, idx) => (
            <InView threshold={0.6}>
              {({ ref, inView }) => (
                <div
                  ref={ref}
                  style={{ position: "relative", width: "100%" }}
                  onClick={() => {
                    setCurShowcase(showcase);
                    setOpenBackdrop(true);
                  }}
                >
                  <ShowcasePoster
                    initial={{ opacity: 0 }}
                    animate={inView ? "visible" : "hidden"}
                    variants={showcaseVariants}
                    whileHover={{
                      cursor: "pointer",
                    }}
                  >
                    <ShowcasePosterTitle
                      initial={{ opacity: 0 }}
                      animate={inView ? "visible" : "hidden"}
                      variants={showcasePosterTitleVariants}
                      style={{ fontSize: "1.6rem" }}
                    >
                      {showcase?.client_name}
                    </ShowcasePosterTitle>
                  </ShowcasePoster>
                  <img
                    src={showcase?.cover_media?.Media_source?.url}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="cover media"
                  />
                </div>
              )}
            </InView>
          ))}
        </Grid>
      </MobileStyledSection>

      <Footer />

      <Backdrop
        style={{
          zIndex: 1000,
          overflowY: "scroll",
        }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "auto",
            paddingTop: "40px",
            top: "0px",
            backgroundColor: "#000",
          }}
        >
          <HighlightOffIcon
            style={{
              position: "absolute",
              fontSize: "2rem",
              color: "#fff",
              margin: "1rem",
              top: "1rem",
              right: "1rem",
            }}
          />
          <Grid container spacing={1} style={{ padding: "2rem 1rem" }}>
            {curShowcase?.banner_media?.Media_type === "image" && (
              <Grid item xs={12}>
                <img
                  src={curShowcase?.banner_media?.Media_source?.url}
                  alt="banner"
                  style={{ height: "100%", width: "90%", objectFit: "contain" }}
                />
              </Grid>
            )}

            {curShowcase?.banner_media?.Media_type === "video" && (
              <Grid item xs={12}>
                <iframe
                  width="100%"
                  // height="300"
                  title="video"
                  src={curShowcase?.banner_media?.Media_link || ""}
                ></iframe>
              </Grid>
            )}
            
            {/* client info */}
            <Grid item xs={12}>
              <ShowcaseBackdropTitle style={{ fontSize: "2rem" }}>
                CLIENT
              </ShowcaseBackdropTitle>
              <ShowcaseBackdropContent style={{ fontSize: "1.6rem" }}>
                {curShowcase?.client_name}
              </ShowcaseBackdropContent>
              <ShowcaseBackdropContent style={{ fontSize: "1.4rem" }}>
                {curShowcase?.title}
              </ShowcaseBackdropContent>

              {/* scope of service */}
              {curShowcase?.scopes?.length && (
                <div>
                  <ShowcaseBackdropTitle style={{ fontSize: "1.6rem" }}>
                    SCOPE OF SERVICES
                  </ShowcaseBackdropTitle>
                  {curShowcase?.scopes?.map((scope, idx) => (
                    <ShowcaseBackdropContent
                      key={idx}
                      style={{ fontSize: "1.6rem" }}
                    >
                      {scope?.scope_text}
                    </ShowcaseBackdropContent>
                  ))}
                </div>
              )}
            </Grid>
          </Grid>

          {/* content */}
          {curShowcase?.showcase_content?.map((content) => (
            <Grid container spacing={3} style={{ padding: "5rem 3rem" }}>
              <Grid item xs={12}>
                <ShowcaseBackdropContent style={{ fontSize: "1.6rem" }}>
                  {content?.title}
                </ShowcaseBackdropContent>
              </Grid>
              <Grid item xs={12}>
                {content?.Media?.map((media, idx) => {
                  switch (media.Media_type) {
                    case "video":
                      return (
                        // <iframe width="560" height="315" src="https://www.youtube.com/embed/82yFsdYuSY8" title="YouTube video player"
                        // frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <iframe
                          width="95%"
                          height="500"
                          title="video"
                          src={media?.Media_link || ""}
                        ></iframe>
                      );
                    case "image":
                      return (
                        <img
                          key={idx}
                          src={media?.Media_source?.url}
                          alt={media?.Media_source?.name || ""}
                          style={{
                            width: "95%",
                            // height: "100%",
                            margin: "10px",
                            objectFit: "contain",
                          }}
                        />
                      );
                    default:
                      return <p>no media</p>;
                  }
                })}
              </Grid>
            </Grid>
          ))}
        </div>
      </Backdrop>
    </>
  );
}

export default MobileShowcaseBetaPage;
