import { Button, Container, Grid } from "@material-ui/core";
import { useState } from "react";
import bg3 from "../../images/backgrounds/bg-3.png";
import {
  MobileClientLogo,
  MobileSectionTitle,
  MobileStyledSection,
} from "../../styles/styles";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import {
  clientLogoContainerVariants,
  clientLogoLineVariants,
} from "../ClientSection";

// 2024 client logos
import amoyLogo from "../../images/logo2024/amoy.png";
import aromeLogo from "../../images/logo2024/arome.png";
import dahSingBankLogo from "../../images/logo2024/dah-sing.png";
import mtrLogo from "../../images/logo2024/mtr.png";
import trappistLogo from "../../images/logo2024/trappist.png";

import electroluxLogo from "../../images/logo2024/electrolux.png";
import nestleLogo from "../../images/logo2024/nestle.png";
import nikonLogo from "../../images/logo2024/nikon.png";
import taoTiLogo from "../../images/logo2024/taoti.jpg";
import toshibaLogo from "../../images/logo2024/toshiba.png";

import belifLogo from "../../images/logo2024/belif.png";
import dermaLabLogo from "../../images/logo2024/dermalab.png";
import niveaLogo from "../../images/logo2024/nivea.png";
import ryoLogo from "../../images/logo2024/ryo.png";
import tumiLogo from "../../images/logo2024/tumi.png";

import mmmLogo from "../../images/logo2024/3m.png";
import dysonLogo from "../../images/logo2024/dyson.png";
import speedtoxLogo from "../../images/logo2024/speedtox.png";
import tigerBalmLogo from "../../images/logo2024/tiger-balm.png";
import wingLungBankLogo from "../../images/logo2024/wing-lung.png";

import chevalierLogo from "../../images/logo2024/chevalier.png";
import chinachemLogo from "../../images/logo2024/china-chem.png";
import chineseEstatesLogo from "../../images/logo2024/chinese-estate.png";
import hendersonLogo from "../../images/logo2024/mcp-henderson.png";
import wangOnLogo from "../../images/logo2024/wang-on-properties.png";

import fehdLogo from "../../images/logo2024/fehd.png";
import freseniusLogo from "../../images/logo2024/fresenius.png";
import hkuLogo from "../../images/logo2024/hku.png";
import mpfaLogo from "../../images/logo2024/mpfa.png";
import sky100Logo from "../../images/logo2024/sky100.png";

import covaLogo from "../../images/logo2024/cova.png";
import dohLogo from "../../images/logo2024/doh.png";
import hkfiLogo from "../../images/logo2024/hkfi.png";
import iaLogo from "../../images/logo2024/ia.png";
import vtcLogo from "../../images/logo2024/vtc.png";

import heinekenLogo from "../../images/logo2024/heineken.png";
import jackJillLogo from "../../images/logo2024/jack-jill.png";
import panasonicLogo from "../../images/logo2024/panasonic.png";
import tsuiWahLogo from "../../images/logo2024/tsui-wah.png";
import wingWahLogo from "../../images/logo2024/wing-wah.png";

import eucerinLogo from "../../images/logo2024/eucerin.png";
import ftLifeLogo from "../../images/logo2024/ftlife.png";
import gardenLogo from "../../images/logo2024/garden-hk.png";
import maximsLogo from "../../images/logo2024/maxims.png";
import whooLogo from "../../images/logo2024/whoo.jpeg";

import asusLogo from "../../images/logo2024/asus.png";
import ciscoLogo from "../../images/logo2024/cisco.png";
import drKongLogo from "../../images/logo2024/dr-kong.png";
import hitachiLogo from "../../images/logo2024/hitachi.png";
import victorinoxLogo from "../../images/logo2024/victorinox.jpg";

import calcichewLogo from "../../images/logo2024/calcichew.png";
import juvedermLogo from "../../images/logo2024/juvederm.png";
import l2Logo from "../../images/logo2024/l2.png";
import laiSunLogo from "../../images/logo2024/lai-sun.png";
import waiYuenTongLogo from "../../images/logo2024/wai-yuen-tong.png";

import commBankLogo from "../../images/logo2024/bank-comm-trustee.png";
import chiefLogo from "../../images/logo2024/chief.png";
import educationUniversityLogo from "../../images/logo2024/edu.png";
import fitchRatingsLogo from "../../images/logo2024/fitch-ratings.png";
import fulumLogo from "../../images/logo2024/fulum.png";

import ajiNoChimiLogo from "../../images/logo2024/aji-no-chinmi.png";
import almondRocaLogo from "../../images/logo2024/almond-roca.png";
import hungFookTongLogo from "../../images/logo2024/hung-fook-tong.png";
import taikooLogo from "../../images/logo2024/taikoo-sugar.png";
import uccLogo from "../../images/logo2024/ucc.png";

import coswayLogo from "../../images/logo2024/cosway.png";
import delifranceLogo from "../../images/logo2024/delifrance.png";
import ninJiOmLogo from "../../images/logo2024/nin-jiom.png";
import sharpLogo from "../../images/logo2024/sharp.png";
import tigerLogo from "../../images/logo2024/tiger.png";

import idealeChefLogo from "../../images/logo2024/ideale-chef.png";
import itsuLogo from "../../images/logo2024/itsu.png";
import miseEnSceneLogo from "../../images/logo2024/mes.png";
import purinaLogo from "../../images/logo2024/purina.png";
import zanussiLogo from "../../images/logo2024/zanussi.png";

import dieselLogo from "../../images/logo2024/diesel.png";
import guessLogo from "../../images/logo2024/guess.png";
import jamiesonLogo from "../../images/logo2024/jamieson.png";
import omekanzLogo from "../../images/logo2024/omekanz.png";
import hartmannLogo from "../../images/logo2024/paul-hartmann.png";

import helaSlimLogo from "../../images/logo2024/hela-slim.png";
import oenobiolLogo from "../../images/logo2024/oenobiol.png";
import ipsenLogo from "../../images/logo2024/smecta-ipsen.png";
import takedaLogo from "../../images/logo2024/takeda.png";
import waferSystemsLogo from "../../images/logo2024/wafer-systems.png";

import checkPointLogo from "../../images/logo2024/check-point.png";
import china3DLogo from "../../images/logo2024/china-3d.png";
import edkoLogo from "../../images/logo2024/edko.png";
import hkriLogo from "../../images/logo2024/hkri.png";
import icacLogo from "../../images/logo2024/icac.png";

import aqumonLogo from "../../images/logo2024/aqumon.png";
import benecolLogo from "../../images/logo2024/benecol.png";
import bondConnectLogo from "../../images/logo2024/bond-connect.png";
import kubeLogo from "../../images/logo2024/kube.png";
import boomLogo from "../../images/logo2024/monex-boom.jpeg";

import addictionLogo from "../../images/logo2024/addiction.png";
import chingJiangYuenLogo from "../../images/logo2024/ching-jiang-yuen.png";
import citraciumLogo from "../../images/logo2024/citracium.png";
import donSimonLogo from "../../images/logo2024/don-simon.png";
import soloLogo from "../../images/logo2024/solo.png";

import careLogo from "../../images/logo2024/care.png";
import aspenLogo from "../../images/logo2024/cortal.png";
import hkDanceLogo from "../../images/logo2024/hk-dance.png";
import hkSinfoniettaLogo from "../../images/logo2024/hk-sinfonietta.png";
import luHKLogo from "../../images/logo2024/lu-hk.png";

function MobileClientSection() {
  const [moreClient, setMoreClient] = useState(false);

  return (
    <MobileStyledSection
      id="mobile-client-section"
      style={{
        backgroundImage: `url(${bg3})`,
        backgroundPosition: "center",
      }}
    >
      <MobileSectionTitle>OUR CLIENT</MobileSectionTitle>
      <InView>
        {({ ref, inView }) => (
          <Container maxWidth="xl">
            <Grid container justify="center" spacing={1}>
              <Grid item xs={12}>
                <motion.div
                  ref={ref}
                  initial={false}
                  animate={inView ? "visible" : "hidden"}
                  variants={clientLogoContainerVariants}
                >
                  {/* line 1 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo
                      style={{ scale: "1.2" }}
                      src={dahSingBankLogo}
                    />
                    <MobileClientLogo style={{ scale: "1.4" }} src={mtrLogo} />
                    <MobileClientLogo src={amoyLogo} />
                  </motion.div>
                  {/* line 2 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo
                      style={{ scale: "1.2" }}
                      src={trappistLogo}
                    />
                    <MobileClientLogo
                      style={{ scale: "0.8" }}
                      src={aromeLogo}
                    />
                    <MobileClientLogo
                      style={{ scale: "0.65" }}
                      src={nestleLogo}
                    />
                  </motion.div>
                  {/* line 3  */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo
                      style={{ scale: "0.65" }}
                      src={taoTiLogo}
                    />
                    <MobileClientLogo
                      style={{ scale: "1.2" }}
                      src={electroluxLogo}
                    />
                    <MobileClientLogo src={toshibaLogo} />
                  </motion.div>
                  {/* line 4 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo src={nikonLogo} />
                    <MobileClientLogo src={niveaLogo} />
                    <MobileClientLogo
                      style={{ scale: "0.6" }}
                      src={dermaLabLogo}
                    />
                  </motion.div>
                  {/* line 5 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo
                      style={{ scale: "0.75" }}
                      src={belifLogo}
                    />
                    <MobileClientLogo style={{ scale: "0.85" }} src={ryoLogo} />
                    <MobileClientLogo style={{ scale: "0.9" }} src={tumiLogo} />
                  </motion.div>
                  {/* line 6 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo src={dysonLogo} />
                    <MobileClientLogo src={tigerBalmLogo} />
                    <MobileClientLogo style={{ scale: "0.55" }} src={mmmLogo} />
                  </motion.div>
                  {/* line 7 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo src={speedtoxLogo} />
                    <MobileClientLogo
                      style={{ scale: "1.2" }}
                      src={wingLungBankLogo}
                    />
                    <MobileClientLogo src={chinachemLogo} />
                  </motion.div>
                  {/* line 8 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo
                      style={{ scale: "1.2" }}
                      src={wangOnLogo}
                    />
                    <MobileClientLogo
                      style={{ scale: "0.65" }}
                      src={hendersonLogo}
                    />
                    <MobileClientLogo
                      style={{ scale: "0.65" }}
                      src={chineseEstatesLogo}
                    />
                  </motion.div>
                  {/* line 9 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo
                      style={{ scale: "0.7" }}
                      src={chevalierLogo}
                    />
                    <MobileClientLogo src={freseniusLogo} />
                    <MobileClientLogo style={{ scale: "1.2" }} src={fehdLogo} />
                  </motion.div>
                  {/* line 10 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <MobileClientLogo style={{ scale: "1.2" }} src={hkuLogo} />
                    <MobileClientLogo style={{ scale: "1.1" }} src={mpfaLogo} />
                    <MobileClientLogo
                      style={{ scale: "0.8" }}
                      src={sky100Logo}
                    />
                  </motion.div>
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "1.1" }}
                        src={dohLogo}
                      />
                      <MobileClientLogo src={vtcLogo} />
                      <MobileClientLogo src={hkfiLogo} />
                    </motion.div>
                  )}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={iaLogo} />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={covaLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.6" }}
                        src={wingWahLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 11 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={tsuiWahLogo} />
                      <MobileClientLogo src={heinekenLogo} />
                      <MobileClientLogo src={jackJillLogo} />
                    </motion.div>
                  )}
                  {/* line 12 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={panasonicLogo} />
                      <MobileClientLogo src={whooLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.9" }}
                        src={eucerinLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 13 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={ftLifeLogo} />
                      <MobileClientLogo src={gardenLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={maximsLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 14 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={victorinoxLogo} />
                      <MobileClientLogo src={drKongLogo} />
                      <MobileClientLogo src={asusLogo} />
                    </motion.div>
                  )}
                  {/* line 15 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={ciscoLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={hitachiLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={juvedermLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 16 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={waiYuenTongLogo} />
                      <MobileClientLogo src={calcichewLogo} />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={laiSunLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 17 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo style={{ scale: "0.8" }} src={l2Logo} />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={chiefLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={fitchRatingsLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 18 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={commBankLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "1.2" }}
                        src={educationUniversityLogo}
                      />
                      <MobileClientLogo src={fulumLogo} />
                    </motion.div>
                  )}
                  {/* line 19 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.8" }}
                        src={uccLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.7" }}
                        src={ajiNoChimiLogo}
                      />
                      <MobileClientLogo src={taikooLogo} />
                    </motion.div>
                  )}
                  {/* line 20 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.7" }}
                        src={almondRocaLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.8" }}
                        src={hungFookTongLogo}
                      />
                      <MobileClientLogo src={delifranceLogo} />
                    </motion.div>
                  )}
                  {/* line 21 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={tigerLogo} />
                      <MobileClientLogo src={sharpLogo} />
                      <MobileClientLogo src={coswayLogo} />
                    </motion.div>
                  )}
                  {/* line 22 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={ninJiOmLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={zanussiLogo}
                      />
                      <MobileClientLogo src={itsuLogo} />
                    </motion.div>
                  )}
                  {/* line 23 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={idealeChefLogo} />
                      <MobileClientLogo src={purinaLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.7" }}
                        src={miseEnSceneLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 24 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.8" }}
                        src={guessLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.65" }}
                        src={dieselLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.95" }}
                        src={hartmannLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 25 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={omekanzLogo} />
                      <MobileClientLogo src={jamiesonLogo} />
                      <MobileClientLogo src={oenobiolLogo} />
                    </motion.div>
                  )}
                  {/* line 26 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={takedaLogo} />
                      <MobileClientLogo src={helaSlimLogo} />
                      <MobileClientLogo src={ipsenLogo} />
                    </motion.div>
                  )}
                  {/* line 27 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={waferSystemsLogo} />
                      <MobileClientLogo src={checkPointLogo} />
                      <MobileClientLogo src={edkoLogo} />
                    </motion.div>
                  )}
                  {/* line 28 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={china3DLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.65" }}
                        src={icacLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.65" }}
                        src={hkriLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 29 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={benecolLogo}
                      />
                      <MobileClientLogo src={aqumonLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.65" }}
                        src={boomLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 30 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.7" }}
                        src={bondConnectLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.8" }}
                        src={kubeLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.65" }}
                        src={soloLogo}
                      />
                    </motion.div>
                  )}
                  {/* line 31 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={chingJiangYuenLogo}
                      />
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={donSimonLogo}
                      />
                      <MobileClientLogo src={addictionLogo} />
                    </motion.div>
                  )}
                  {/* line 32 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo
                        style={{ scale: "0.85" }}
                        src={citraciumLogo}
                      />
                      <MobileClientLogo src={aspenLogo} />
                      <MobileClientLogo src={hkDanceLogo} />
                    </motion.div>
                  )}
                  {/* line 33 */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <MobileClientLogo src={hkSinfoniettaLogo} />
                      <MobileClientLogo src={luHKLogo} />
                      <MobileClientLogo
                        style={{ scale: "0.8" }}
                        src={careLogo}
                      />
                    </motion.div>
                  )}
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        )}
      </InView>
      <Button
        style={{
          color: "white",
          fontSize: "1.2rem",
          fontFamily: "HelveticaNeueBold",
        }}
        onClick={() => {
          setMoreClient(!moreClient);
          console.log("more client:", moreClient);
        }}
      >
        {moreClient ? "Less" : "More"}
      </Button>
    </MobileStyledSection>
  );
}
export default MobileClientSection;
