import React, { useState, memo } from "react";
import bg2 from "../../images/backgrounds/bg-2.png";
import { Link } from "react-router-dom";
import { MobileStyledSection, MobileSectionTitle } from "../../styles/styles";
import { Grid, Button } from "@material-ui/core";
import styled from "styled-components";

// icons
import mediaIcon from "../../images/icons/media-icon.png";
import socialIcon from "../../images/icons/social-icon.png";
import creativeIcon from "../../images/icons/creative-icon.png";
import { AppConstants } from "../../data/appConstants";
import InView from "react-intersection-observer";

const MobileCard = styled.div`
  margin: 10px auto;
  width: 90%;
  /* height: 550px; */

  transition: all 1s;
`;
const MobileCardImage = styled.img`
  width: 80%;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const MobileCardText = styled.li`
  margin: 0;
  padding: 10px;
  font-family: "HelveticaNeueBold";
  font-size: 1rem;

  color: #fff;
  text-align: left;
`;

function MobileCardFlipButton() {
  return (
    <Button
      variant="contained"
      style={{
        width: "50vw",
        maxWidth: "200px",
        border: "1px solid white",
        borderRadius: "20px",
        color: "white",
        backgroundColor: "black",
      }}
      component={Link}
      to="/showcase"
    >
      MORE
    </Button>
  );
}

function MobileSolutionSection() {
  return (
    <MobileStyledSection
      id="solution-section"
      style={{
        backgroundImage: `url(${bg2})`,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <MobileSectionTitle>SOLUTION</MobileSectionTitle>
        </Grid>
        <Grid
          container
          spacing={1}
          justify="center"
          alignItems="stretch"
          style={{ paddingTop: "1rem" }}
        >
          <Grid item xs={10}>
            <InView>
              {({ ref, inView }) => (
                <MobileCard
                  style={{
                    backgroundColor: inView
                      ? "rgba(0, 188, 228, 0.8)"
                      : "black",
                  }}
                >
                  <MobileCardImage src={mediaIcon} />
                  <div ref={ref} style={{ transform: "translateY(-60px)" }}>
                    <ul>
                      {AppConstants.home.solution.media.en_content.map(
                        (text) => (
                          <MobileCardText>{text}</MobileCardText>
                        )
                      )}
                    </ul>
                    <MobileCardFlipButton />
                  </div>
                </MobileCard>
              )}
            </InView>
          </Grid>

          <Grid item xs={10}>
            <InView>
              {({ ref, inView }) => (
                <MobileCard
                  style={{
                    backgroundColor: inView
                      ? "rgba(0, 188, 228, 0.8)"
                      : "black",
                  }}
                >
                  <MobileCardImage src={creativeIcon} />
                  <div ref={ref} style={{ transform: "translateY(-60px)" }}>
                    <ul>
                      {AppConstants.home.solution.creative.en_content.map(
                        (text) => (
                          <MobileCardText>{text}</MobileCardText>
                        )
                      )}
                    </ul>
                    <MobileCardFlipButton />
                  </div>
                </MobileCard>
              )}
            </InView>
          </Grid>

          <Grid item xs={10}>
            <InView>
              {({ ref, inView }) => (
                <MobileCard
                  style={{
                    backgroundColor: inView
                      ? "rgba(0, 188, 228, 0.8)"
                      : "black",
                  }}
                >
                  <MobileCardImage src={socialIcon} />
                  <div ref={ref} style={{ transform: "translateY(-60px)" }}>
                    <ul>
                      {AppConstants.home.solution.social.en_content.map(
                        (text) => (
                          <MobileCardText>{text}</MobileCardText>
                        )
                      )}
                    </ul>
                    <MobileCardFlipButton />
                  </div>
                </MobileCard>
              )}
            </InView>
          </Grid>
      
        </Grid>
      </Grid>
    </MobileStyledSection>
  );
}

export default MobileSolutionSection;
