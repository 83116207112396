import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { AnimateSharedLayout, motion } from "framer-motion";
import { HomeSections } from "../data/appConstants";

const spring = { type: "spring", stiffness: 500, damping: 30 };

const StyledUl = styled.ul`
  list-style: none;
  margin: 0;
  padding-right: 50px;
`;

const StyledLi = styled.li`
  display: block;
  margin: 1rem;
  width: 5px;
  height: 12vh;
  background-color: lightgray;
  border-radius: 100px;
`;

const SwiperBar = styled(motion.div)`
  display: block;
  margin: 0;
  width: 5px;
  height: 12vh;
  background-color: lightblue;
  border-radius: 100px;
`;

interface SwiperProps {
  activeSection: string;
  setActiveSection: Dispatch<SetStateAction<string>>;
}

function Swiper({ activeSection, setActiveSection }: SwiperProps) {
  //   const [selected, setSelected] = useState(0);
  return (
    <div>
      <AnimateSharedLayout>
        <StyledUl>
          <StyledLi>
            {activeSection === HomeSections[0].id && (
              <SwiperBar
                layoutId="swiper"
                initial={false}
                animate={{
                  backgroundColor: "#09f",
                }}
                transition={spring}
              />
            )}
          </StyledLi>
          <StyledLi>
            {activeSection === HomeSections[1].id && (
              <SwiperBar
                layoutId="swiper"
                initial={false}
                animate={{
                  backgroundColor: "#09f",
                }}
                transition={spring}
              />
            )}
          </StyledLi>
          <StyledLi>
            {activeSection === HomeSections[2].id && (
              <SwiperBar
                layoutId="swiper"
                initial={false}
                animate={{
                  backgroundColor: "#09f",
                }}
                transition={spring}
              />
            )}
          </StyledLi>
          <StyledLi>
            {activeSection === HomeSections[3].id && (
              <SwiperBar
                layoutId="swiper"
                initial={false}
                animate={{
                  backgroundColor: "#09f",
                }}
                transition={spring}
              />
            )}
          </StyledLi>
          <StyledLi>
            {activeSection === HomeSections[4].id && (
              <SwiperBar
                layoutId="swiper"
                initial={false}
                animate={{
                  backgroundColor: "#09f",
                }}
                transition={spring}
              />
            )}
          </StyledLi>
        </StyledUl>
      </AnimateSharedLayout>
    </div>
  );
}

export default Swiper;
