import { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

// styles
import {
  StyledSection,
  SectionTitle,
  StyledH1,
  ShowcasePoster,
} from "../../styles/styles";

// material icons
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// components / pages
import ScrollRightButton from "../../components/ScrollRightButton";
import LoadingPage from "../../pages/LoadingPage";

// types
import { SectionProps } from "../../types/types";
import { IShowcase, IClientShowcase } from "../../types/types";

// constants
import { herokuBaseUrl } from "../../data/appConstants";

const TOTAL_IMAGES = 3;

function WebShowcaseSection({
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const history = useHistory();
  const BASE_URL = herokuBaseUrl;
  const [loadedImages, setLoadedImages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [showcaseClient, setShowcaseClient] = useState<Array<IClientShowcase>>(
    []
  );

  useEffect(() => {
    console.log("web showcase section loaded images", loadedImages);
    if (loadedImages === TOTAL_IMAGES) {
      setLoadedSections((loadedSections) => loadedSections + 1);
    }
  }, [loadedImages, setLoadedSections]);

  useEffect(() => {
    const fetchStrapi = async () => {
      setIsFetching(true);
      const fetchOption = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        // console.log("web showcase section start fetching showcase data");
        const fetchOrderResponse = await fetch(
          `https://${BASE_URL}/home-showcase-orders`,
          fetchOption
        );
        const homeShowcaseOrder = await fetchOrderResponse.json();
        const showcaseIds = homeShowcaseOrder[0].showcase_ids;

        const fetchShowcasesResponse = showcaseIds.map(
          async (showcase: { showcase_id: string }) =>
            await fetch(
              `https://${BASE_URL}/showcases/${showcase.showcase_id}`,
              fetchOption
            ).then((p) => p.json())
        );

        console.log(fetchShowcasesResponse);
        const [showcase1, showcase2, showcase3]: Array<IShowcase> =
          await Promise.all(fetchShowcasesResponse);

        const client1: IClientShowcase = {
          name: showcase1.client_name,
          imageURL: showcase1.cover_media.Media_source.url,
        };
        const client2: IClientShowcase = {
          name: showcase2.client_name,
          imageURL: showcase2.cover_media.Media_source.url,
        };
        const client3: IClientShowcase = {
          name: showcase3.client_name,
          imageURL: showcase3.cover_media.Media_source.url,
        };
        const clients = [client1, client2, client3];
        setShowcaseClient(clients);
        setIsFetching(false);
      } catch (err) {
        console.log(err);
        setIsFetching(false);
      }
    };
    fetchStrapi();
  }, [BASE_URL]);

  if (isFetching) {
    return <LoadingPage />;
  }

  return (
    <StyledSection
      id="web-showcase-section"
      style={{
        backgroundColor: "black",
      }}
    >
      <Grid item xs={12}>
        <SectionTitle>SHOWCASE</SectionTitle>
      </Grid>

      {/* showcase images, 3 columns */}
      <Grid item xs={12}>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ margin: 0, paddingTop: "5rem" }}
        >
          {showcaseClient.length &&
            showcaseClient.map((client: IClientShowcase) => (
              <div style={{ position: "relative", width: "33.33%" }}>
                <ShowcasePoster
                  initial={{ opacity: 1 }}
                  whileHover={{
                    opacity: 0,
                    cursor: "pointer",
                    transition: { duration: 1 },
                  }}
                  onClick={() => history.push("./showcase")}
                >
                  <StyledH1>{client.name}</StyledH1>
                  {/* {client.taglines.map((tagline) => (
                  <StyledShowcaseContent>{tagline}</StyledShowcaseContent>
                ))} */}
                </ShowcasePoster>
                <img
                  src={client.imageURL}
                  alt={client.name}
                  onLoad={() =>
                    setLoadedImages((loadedImages) => loadedImages + 1)
                  }
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
            ))}
        </Grid>
      </Grid>

      {/* more button, click to go to showcase page */}
      <Grid item xs={12} justify="flex-end">
        <Button
          style={{
            width: "30vw",
            height: "10vh",
            minHeight: "50px",
            position: "absolute",
            right: 0,
          }}
          onClick={() => {
            console.log("more clicked");
            history.push("/showcase");
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
                fontFamily: "HelveticaNeueBold",
                fontSize: "1.4rem",
                paddingRight: "2rem",
                height: "2rem;",
              }}
            >
              MORE
            </p>
            <ScrollRightButton />
          </div>
        </Button>
      </Grid>
    </StyledSection>
  );
}

export default WebShowcaseSection;
