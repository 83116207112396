import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import AboutUsPage from "./pages/AboutUsPage";
import HomePage from "./pages/HomePage";
import ShowcaseBetaPage from "./pages/ShowcaseBetaPage";
import ShowcasePage from "./pages/ShowcasePage";
import { Helmet } from "react-helmet";
import LoadingPage from "./pages/LoadingPage";

// google analytics
import ReactGA from "react-ga4"; // update for GA4
const trackingId = "G-V3Q3YGVDKH"; // update Mar 2024

// const trackingId = "UA-204456275-1";
ReactGA.initialize(trackingId);
ReactGA.set({
  category: "Load App",
  action: "User visit the website",

  // userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
});

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Digital marketing agency in Hong Kong | RDS Digital</title>
        <meta
          name="description"
          content="Holistic Digital Solutions include digital marketing, social marketing, influencers marketing, 小紅書 廣告, online media planning"
        />
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/about-us">
            <AboutUsPage />
          </Route>
          <Route exact path="/showcase">
            <ShowcasePage />
          </Route>
          <Route exact path="/showcase-preview">
            <ShowcaseBetaPage />
          </Route>
          <Route exact path="/loading">
            <LoadingPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
