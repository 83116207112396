import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";

// logo
import linkinLogo from "../../images/linkin.png";
import facebookLogo from "../../images/facebook.png";
import youtubeLogo from "../../images/youtube.png";
import googlePartnerLogo from "../../images/google-partner-logo.jpg";
import hashtagLogo from "../../images/hashtag.png";
import agencyAward2022Logo from "../../images/agency-awards-2022-logo.png";
import markiesLogo from "../../images/markies-award.png";
import digizLogo from "../../images/digiz-bronze.jpg";
import mea2023Logo from "../../images/mea-award-2023.jpg";
import markies2024Logo from "../../images/markies-award-2024.jpg";
// components
import LinkedLogo from "../LinkedLogo";

function MobileFooter() {
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <Grid container>
        {/* google partner */}
        <Grid item xs={6}>
          <Box m={1}>
            <LinkedLogo
              pathname="https://www.google.com/partners/agency?id=8265572971"
              logo={googlePartnerLogo}
              altname="google parter"
            />

            <LinkedLogo
              pathname=""
              logo={hashtagLogo}
              altname="hashtag bronze winner"
            />
            <LinkedLogo
              pathname=""
              logo={agencyAward2022Logo}
              altname="Agency of the Year Award 2022"
            />
            <LinkedLogo
              pathname=""
              logo={markiesLogo}
              altname="Markies Award 2022"
            />
            <LinkedLogo
              pathname=""
              logo={digizLogo}
              altname="Digiz Award 2022"
            />
            <LinkedLogo
              pathname=""
              logo={mea2023Logo}
              altname="MEA Award 2023"
            />
            <LinkedLogo
              pathname=""
              logo={markies2024Logo}
              altname="Markies Award 2024"
            />
          </Box>
        </Grid>

        {/* social media */}
        <Grid item xs={6}>
          <Box m={1}>
            <LinkedLogo
              pathname="https://www.linkedin.com/company/rdsdigitalagency"
              logo={linkinLogo}
              altname="linkin"
            />
            <LinkedLogo
              pathname="https://www.facebook.com/rdsdigitalagency"
              logo={facebookLogo}
              altname="facebook"
            />
            <LinkedLogo
              pathname="https://www.youtube.com/channel/UCPdNqcwRf9OlU0F8QgpTWmg"
              logo={youtubeLogo}
              altname="youtube"
            />
          </Box>
        </Grid>

        {/* text */}
        <Grid item xs={12}>
          <Box m={1}>
            <Typography
              variant="body2"
              align="left"
              style={{ marginLeft: "1rem", fontSize: "0.6rem" }}
            >
              COPYRIGHT {new Date().getFullYear()} 'RUBISON DIGITAL SOLUTIONS
              LIMITED' ALL RIGHTS RESERVED
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default MobileFooter;
