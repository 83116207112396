import React from "react";
import { Link } from "react-router-dom";

interface LinkedLogoProps {
  pathname: string;
  logo: string;
  altname: string;
}

function LinkedLogo({ pathname, logo, altname }: LinkedLogoProps) {
  return (
    <Link to={{ pathname }} target="_blank">
      <img
        src={logo}
        style={{
          height: "22px",
        //   width: "4vw",
        //   minWidth: "30px",
          margin: '5px'
        }}
        alt={altname}
      />
    </Link>
  );
}

export default LinkedLogo;
