import { useState, useEffect } from "react";
import {
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

// components / pages
import LoadingPage from "./LoadingPage";
import MobileShowcaseBetaPage from "./mobile/MobileShowcaseBetaPage";
import WebShowcaseBetaPage from "./web/WebShowcaseBetaPage";

import { IShowcase, IShowcaseOrder } from "../types/types";
import { herokuBaseUrl } from "../data/appConstants";

export interface ShowcasePageProps {
  showcases: IShowcase[];
}

function ShowcaseBetaPage() {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
  const BASE_URL = herokuBaseUrl;

  const [showcaseData, setShowcaseData] = useState<Array<IShowcase>>([]);
  const [isFetching, setIsFetching] = useState(false);

  const reorder = (showcases: IShowcase[], orders: IShowcaseOrder[]) => {
    const sortedShowcases = orders.map(
      (order) =>
        showcases.filter((showcase) => showcase.showcase_id === order.showcase_id)[0]
    );
    return sortedShowcases;
  };

  useEffect(() => {
    const fetchStrapi = async () => {
      setIsFetching(true);
      const fetchOption = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        // console.log("start fetching showcase data");
        const fetches = [
          fetch(`https://${BASE_URL}/showcases`, fetchOption),
          fetch(`https://${BASE_URL}/preview-showcase-orders`, fetchOption),
        ];

        const [showcaseResponse, showcaseOrderResponse] = await Promise.all(
          fetches
        );

        const showcases = await showcaseResponse.json();
        const showcaseOrder = await showcaseOrderResponse.json();
        // console.log("showscases", showcases);
        // console.log("showcase order", showcaseOrder[0].showcase_ids);
        const orderedShowcases = reorder(showcases, showcaseOrder[0].showcase_ids);
        // console.log('ordered showcase', orderedShowcases);
        // console.log('showcaseData', showcaseData);
        setShowcaseData(orderedShowcases);
        // console.log("ordered showcases");
        // setData(content);
        // console.log(data);
        setIsFetching(false);
      } catch (err) {
        console.log(err);
        setIsFetching(false);
      }
    };
    fetchStrapi();
  }, [BASE_URL]);

  if (isFetching) {
    return <LoadingPage />;
  }

  return mobileView ? (
    <MobileShowcaseBetaPage
      showcases={showcaseData}
    />
  ) : (
    <WebShowcaseBetaPage
      showcases={showcaseData}
    />
  );
}

export default ShowcaseBetaPage;
