import React, { useState, useEffect, memo } from "react";
import bg2 from "../../images/backgrounds/bg-2.png";
import { Link, useLocation, useHistory } from "react-router-dom";

import { StyledSection, SectionTitle } from "../../styles/styles";
import { Container, Grid, Button } from "@material-ui/core";

import styled from "styled-components";

// icons
import mediaIcon from "../../images/icons/trim-media-icon.png";
import socialIcon from "../../images/icons/trim-social-icon.png";
import creativeIcon from "../../images/icons/trim-creative-icon.png";
// import notesIcon from "../../images/icons/notes.png";
// import bulbIcon from "../../images/icons/bulb.png";
// import notebookIcon from "../../images/icons/notebook.png";
import ScrollDownButton from "../ScrollDownButton";
import { SectionProps } from "../../types/types";
import { AppConstants } from "../../data/appConstants";

import Awards from "../../images/awards2.png";

const CardBackText = styled.li`
  margin: 0;
  padding-bottom: 10px;
  font-family: "HelveticaNeueBold";
  font-size: 1rem;
  color: #fff;
  text-align: left;
`;

const StyledFlipCardImage = styled.img`
  width: 90%;
`;

const StyledFlipCardUl = styled.ul`
  margin: 0;
  padding: 0;
`;

function CardFlipButton() {
  return (
    <Button
      variant="contained"
      style={{
        width: "10vw",
        maxWidth: "200px",
        border: "1px solid white",
        borderRadius: "20px",
        color: "white",
        backgroundColor: "black",
      }}
      component={Link}
      to="/showcase"
    >
      MORE
    </Button>
  );
}
const TOTAL_IMAGES = 6;

function WebSolutionSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    console.log("web solution section loaded images", loadedImages);
    if (loadedImages == TOTAL_IMAGES) {
      setLoadedSections((loadedSections) => loadedSections + 1);
    }
  }, [loadedImages]);

  return (
    <StyledSection
      id="solution-section"
      style={{
        backgroundImage: `url(${bg2})`,
      }}
    >
      <Container maxWidth="lg">
        <Grid item xs={12}>
          <SectionTitle>SOLUTION</SectionTitle>
        </Grid>

        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="stretch"
          style={{ paddingTop: "6rem" }}
        >
          <Grid item xs={12} sm={4}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <StyledFlipCardImage
                    src={mediaIcon}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                </div>
                <div className="flip-card-back">
                  <StyledFlipCardImage
                    src={mediaIcon}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  <StyledFlipCardUl>
                    {AppConstants.home.solution.media.en_content.map((text) => (
                      <CardBackText>{text}</CardBackText>
                    ))}
                  </StyledFlipCardUl>
                  <CardFlipButton />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <StyledFlipCardImage
                    src={creativeIcon}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                </div>
                <div className="flip-card-back">
                  <StyledFlipCardImage
                    src={creativeIcon}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  <StyledFlipCardUl>
                    {AppConstants.home.solution.creative.en_content.map(
                      (text) => (
                        <CardBackText>{text}</CardBackText>
                      )
                    )}
                  </StyledFlipCardUl>
                  <CardFlipButton />
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <StyledFlipCardImage
                    src={socialIcon}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                </div>
                <div className="flip-card-back">
                  <StyledFlipCardImage
                    src={socialIcon}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />

                  <StyledFlipCardUl>
                    {AppConstants.home.solution.social.en_content.map(
                      (text) => (
                        <CardBackText>{text}</CardBackText>
                      )
                    )}
                  </StyledFlipCardUl>
                  <CardFlipButton />
                </div>
              </div>
            </div>
          </Grid>

        
        </Grid>

        {/* scroll down button */}
        <div
          style={{
            position: "absolute",
            bottom: "5vh",
            left: "50%",
            transform: "translate(-50%, 85%)",
          }}
        >
          <ScrollDownButton
            goToSection="web-client-section"
            setActiveSection={setActiveSection}
          />
        </div>
        {/* </Grid> */}
        {/* </div> */}
      </Container>
    </StyledSection>
  );
}

export default WebSolutionSection;
