import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { IconButton } from "@material-ui/core";

interface ButtonProps {
  goToSection: string;
  setActiveSection: Dispatch<SetStateAction<string>>;
}

function ScrollDownButton({ setActiveSection, goToSection }: ButtonProps) {
  return (
    <motion.div
      style={{ display: "block", height: "200px" }}
      initial={{ y: 0, opacity: 1 }}
      animate={{ y: 50, opacity: 0 }}
      transition={{
        repeat: Infinity,
        duration: 1.5,
      }}
    >
      <IconButton onClick={() => setActiveSection(goToSection)}>
        <ArrowDownwardIcon fontSize="large" htmlColor="white" />
      </IconButton>
     
    </motion.div>
  );
}

export default ScrollDownButton;
