import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InView } from "react-intersection-observer";

// styles
import {
  MobileStyledSection,
  MobileSectionTitle,
  MobileStyledH1,
  MobileShowcasePoster,
} from "../../styles/styles";

// material icons
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// types
// import { SectionProps } from "../../types/types";
import { IShowcase, IClientShowcase } from "../../types/types";

// constants
import { herokuBaseUrl } from "../../data/appConstants";

// animations
export const showcaseVariants = {
  hidden: { opacity: 0, transition: { duration: 1 } },
  visible: { opacity: 1, transition: { duration: 1 } },
};

function MobileShowcaseSection() {
  const history = useHistory();
  const BASE_URL = herokuBaseUrl;
  const [isFetching, setIsFetching] = useState(false);
  const [showcaseClient, setShowcaseClient] = useState<Array<IClientShowcase>>(
    []
  );

  useEffect(() => {
    const fetchStrapi = async () => {
      setIsFetching(true);
      const fetchOption = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      try {
        // console.log("mobile showcase section start fetching showcase data");
        const fetchOrderResponse = await fetch(
          `https://${BASE_URL}/home-showcase-orders`,
          fetchOption
        );
        const homeShowcaseOrder = await fetchOrderResponse.json();
        const showcaseIds = homeShowcaseOrder[0].showcase_ids;

        const fetchShowcasesResponse = showcaseIds.map(
          async (showcase: { showcase_id: string }) =>
            await fetch(
              `https://${BASE_URL}/showcases/${showcase.showcase_id}`,
              fetchOption
            ).then((p) => p.json())
        );

        console.log(fetchShowcasesResponse);
        const [showcase1, showcase2, showcase3]: Array<IShowcase> =
          await Promise.all(fetchShowcasesResponse);

        const client1: IClientShowcase = {
          name: showcase1.client_name,
          imageURL: showcase1.cover_media.Media_source.url,
        };
        const client2: IClientShowcase = {
          name: showcase2.client_name,
          imageURL: showcase2.cover_media.Media_source.url,
        };
        const client3: IClientShowcase = {
          name: showcase3.client_name,
          imageURL: showcase3.cover_media.Media_source.url,
        };
        const clients = [client1, client2, client3];
        setShowcaseClient(clients);
        setIsFetching(false);
      } catch (err) {
        console.log(err);
        setIsFetching(false);
      }
    };
    fetchStrapi();
  }, [BASE_URL]);

  return (
    <MobileStyledSection
      id="mobile-showcase-section"
      style={{
        backgroundColor: "black",
        height: "auto",
      }}
    >
      <MobileSectionTitle>SHOWCASE</MobileSectionTitle>
      <div style={{ marginTop: "2rem" }}>
        {showcaseClient.length &&
          showcaseClient.map((client: IClientShowcase) => (
            <InView>
              {({ ref, inView }) => (
                <div style={{ position: "relative", width: "100vw" }} ref={ref}>
                  <MobileShowcasePoster
                    initial={{ opacity: 1 }}
                    animate={inView ? "visible" : "hidden"}
                    variants={showcaseVariants}
                    onClick={() => history.push("./showcase")}
                  >
                    <MobileStyledH1
                      style={{
                        fontSize: "3rem",
                        marginLeft: "2rem",
                        transform: "translateY(100%)",
                      }}
                    >
                      {client.name}
                    </MobileStyledH1>
                  </MobileShowcasePoster>
                  <img
                    src={client.imageURL}
                    alt={client.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </InView>
          ))}
      </div>
    </MobileStyledSection>
  );
}

export default MobileShowcaseSection;
