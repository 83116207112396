import React from "react";
import LoadingLogo from "../components/LoadingLogo";

function LoadingPage() {
  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: "black" }}>
      <div
        style={{
          width: "50%",
          height: "50%",
          margin: 0,
          padding: 0,
          position: "absolute",
          // left: "50%",
          // top: "50%",
          transform: "translate(50%, 50%)",
          overflow: "hidden",
        }}
      >
        <LoadingLogo />
      </div>
    </div>
  );
}

export default LoadingPage;
