import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  memo,
} from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  IconButton,
  useMediaQuery,
  Hidden,
  TextField,
  useTheme,
} from "@material-ui/core";
import {
  MobileStyledSection,
  StyledSection,
  SectionTitle,
  StyledH1,
  StyledShowcaseContent,
  FadeInLetter,
  ClientLogo,
  MobileFadeInLetter,
  ShowcasePoster,
} from "../../styles/styles";
import { animationControls, motion, useAnimation } from "framer-motion";

// components
import Navbar from "../../components/Navbar";
import MobileNavbar from "../../components/MobileNavbar";
import ParticlesBackground from "../../components/ParticlesBackground";

// background images
import bg1 from "../../images/backgrounds/bg-1.png";

// icons
import weAreIcon from "../../images/icons/we-are.png";

// alphabet images
import aBlue from "../../images/fonts/a-blue.png";
import bBlue from "../../images/fonts/b-blue.png";
import cWhite from "../../images/fonts/c-white.png";
import eBlue from "../../images/fonts/e-blue.png";
import gBlue from "../../images/fonts/g-blue.png";
import gWhite from "../../images/fonts/g-white.png";
import hWhite from "../../images/fonts/h-white.png";
import kBlue from "../../images/fonts/k-blue.png";
import nWhite from "../../images/fonts/n-white.png";
import oWhite from "../../images/fonts/o-white.png";
import rBlue from "../../images/fonts/r-blue.png";
import rWhite from "../../images/fonts/r-white.png";
import tWhite from "../../images/fonts/t-white.png";
import uWhite from "../../images/fonts/u-white.png";
import vBlue from "../../images/fonts/v-blue.png";

import { HomeSections } from "../../data/appConstants";
import ScrollDownButton from "../ScrollDownButton";

import { SectionProps } from "../../types/types";

const TOTAL_IMAGES = 1;

function WebBannerSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const lettersControls1 = useAnimation();
  const lettersControls2 = useAnimation();
  const [activeLetters, setActiveLetters] = useState(1);
  const [loadedImages, setLoadedImages] = useState(0);

  //   const theme = useTheme();
  //   const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  //   start animation
  useEffect(() => {
    lettersControls1.start("show");
  }, []);

  // alternate animation
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log(`current active letters: ${activeLetters}`);
      if (activeLetters === 0) {
        lettersControls1.start("show");
        lettersControls2.start("hidden");
      }

      if (activeLetters === 1) {
        lettersControls1.start("hidden");
        lettersControls2.start("show");
      }
      setActiveLetters(activeLetters ? 0 : 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeLetters, setActiveLetters]);

  useEffect(() => {
    console.log("web banner section loaded images", loadedImages);
    if (loadedImages == TOTAL_IMAGES) {
      setLoadedSections((loadedSections) => loadedSections + 1);
    }
  }, [loadedImages]);

  return (
    <StyledSection
      id="banner-section"
      style={{
        backgroundImage: `url(${bg1})`,
      }}
    >
      <Navbar />

      {/* fade in letters */}

      {/* letters 1 */}
      <div
        style={{
          position: "absolute",
          width: "50%",
          height: "100%",
          transform: "translate(50%,45%)",
        }}
      >
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 0.75 },
            },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={cWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 1.5 } },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={oWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 2.25 },
            },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={nWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 3 } },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={vBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 1.25 },
            },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={eBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 2 } },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={rBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 2.75 },
            },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={gBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 3.5 } },
          }}
          initial="hidden"
          animate={lettersControls1}
          src={eBlue}
        />
      </div>

      {/* letters 2 */}
      <div
        style={{
          position: "absolute",
          width: "50%",
          height: "100%",
          transform: "translate(50%,40%)",
        }}
      >
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 1.25 },
            },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={bBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 0.75 },
            },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={rBlue}
        />

        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 2.5 } },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={eBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 0.25 },
            },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={aBlue}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 1.75 },
            },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={kBlue}
        />
        <br />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 1.5 } },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={tWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 0 } },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={hWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 2 } },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={rWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 2.75 },
            },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={oWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: {
              opacity: 1,
              transition: { duration: 1, delay: 2.25 },
            },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={uWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={gWhite}
        />
        <FadeInLetter
          variants={{
            hidden: { opacity: 0, transition: { duration: 1 } },
            show: { opacity: 1, transition: { duration: 1, delay: 1 } },
          }}
          initial="hidden"
          animate={lettersControls2}
          src={hWhite}
        />
      </div>

      <ParticlesBackground />

      {/* scroll down button */}
      <div
        style={{
          position: "absolute",
          bottom: "3rem",
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      >
        <img
          src={weAreIcon}
          onLoad={() => setLoadedImages((loadedImages) => loadedImages + 1)}
          style={{ width: "30%" }}
        />

        <ScrollDownButton
          goToSection="web-solution-section"
          setActiveSection={setActiveSection}
        />
      </div>
    </StyledSection>
  );
}

export default WebBannerSection;
