import { memo } from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

// types
import { SectionProps } from "../types/types";

// components
import WebSolutionSection from "./web/WebSolutionSection";
import MobileSolutionSection from "./mobile/MobileSolutionSection";

function SolutionSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? (
    <MobileSolutionSection />
  ) : (
    <WebSolutionSection
      setActiveSection={setActiveSection}
      setLoadedSections={setLoadedSections}
      loadedSections={loadedSections}
    />
  );
}

export default memo(SolutionSection);
