import { Button, Container, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import bg3 from "../../images/backgrounds/bg-3.png";
import { ClientLogo, SectionTitle, StyledSection } from "../../styles/styles";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import {
  clientLogoContainerVariants,
  clientLogoLineVariants,
} from "../ClientSection";

// 2024 client logos
import amoyLogo from "../../images/logo2024/amoy.png";
import aromeLogo from "../../images/logo2024/arome.png";
import dahSingBankLogo from "../../images/logo2024/dah-sing.png";
import mtrLogo from "../../images/logo2024/mtr.png";
import trappistLogo from "../../images/logo2024/trappist.png";

import electroluxLogo from "../../images/logo2024/electrolux.png";
import nestleLogo from "../../images/logo2024/nestle.png";
import nikonLogo from "../../images/logo2024/nikon.png";
import taoTiLogo from "../../images/logo2024/taoti.jpg";
import toshibaLogo from "../../images/logo2024/toshiba.png";

import belifLogo from "../../images/logo2024/belif.png";
import dermaLabLogo from "../../images/logo2024/dermalab.png";
import niveaLogo from "../../images/logo2024/nivea.png";
import ryoLogo from "../../images/logo2024/ryo.png";
import tumiLogo from "../../images/logo2024/tumi.png";

import mmmLogo from "../../images/logo2024/3m.png";
import dysonLogo from "../../images/logo2024/dyson.png";
import speedtoxLogo from "../../images/logo2024/speedtox.png";
import tigerBalmLogo from "../../images/logo2024/tiger-balm.png";
import wingLungBankLogo from "../../images/logo2024/wing-lung.png";

import chevalierLogo from "../../images/logo2024/chevalier.png";
import chinachemLogo from "../../images/logo2024/china-chem.png";
import chineseEstatesLogo from "../../images/logo2024/chinese-estate.png";
import hendersonLogo from "../../images/logo2024/mcp-henderson.png";
import wangOnLogo from "../../images/logo2024/wang-on-properties.png";

import fehdLogo from "../../images/logo2024/fehd.png";
import freseniusLogo from "../../images/logo2024/fresenius.png";
import hkuLogo from "../../images/logo2024/hku.png";
import mpfaLogo from "../../images/logo2024/mpfa.png";
import sky100Logo from "../../images/logo2024/sky100.png";

import dohLogo from "../../images/logo2024/doh.png";
import vtcLogo from "../../images/logo2024/vtc.png";
import hkfiLogo from "../../images/logo2024/hkfi.png";
import iaLogo from "../../images/logo2024/ia.png";
import covaLogo from "../../images/logo2024/cova.png";

import heinekenLogo from "../../images/logo2024/heineken.png";
import jackJillLogo from "../../images/logo2024/jack-jill.png";
import panasonicLogo from "../../images/logo2024/panasonic.png";
import tsuiWahLogo from "../../images/logo2024/tsui-wah.png";
import wingWahLogo from "../../images/logo2024/wing-wah.png";

import eucerinLogo from "../../images/logo2024/eucerin.png";
import ftLifeLogo from "../../images/logo2024/ftlife.png";
import gardenLogo from "../../images/logo2024/garden-hk.png";
import maximsLogo from "../../images/logo2024/maxims.png";
import whooLogo from "../../images/logo2024/whoo.jpeg";

import asusLogo from "../../images/logo2024/asus.png";
import ciscoLogo from "../../images/logo2024/cisco.png";
import drKongLogo from "../../images/logo2024/dr-kong.png";
import hitachiLogo from "../../images/logo2024/hitachi.png";
import victorinoxLogo from "../../images/logo2024/victorinox.jpg";

import calcichewLogo from "../../images/logo2024/calcichew.png";
import juvedermLogo from "../../images/logo2024/juvederm.png";
import l2Logo from "../../images/logo2024/l2.png";
import laiSunLogo from "../../images/logo2024/lai-sun.png";
import waiYuenTongLogo from "../../images/logo2024/wai-yuen-tong.png";

import commBankLogo from "../../images/logo2024/bank-comm-trustee.png";
import chiefLogo from "../../images/logo2024/chief.png";
import educationUniversityLogo from "../../images/logo2024/edu.png";
import fitchRatingsLogo from "../../images/logo2024/fitch-ratings.png";
import fulumLogo from "../../images/logo2024/fulum.png";

import ajiNoChimiLogo from "../../images/logo2024/aji-no-chinmi.png";
import almondRocaLogo from "../../images/logo2024/almond-roca.png";
import hungFookTongLogo from "../../images/logo2024/hung-fook-tong.png";
import taikooLogo from "../../images/logo2024/taikoo-sugar.png";
import uccLogo from "../../images/logo2024/ucc.png";

import coswayLogo from "../../images/logo2024/cosway.png";
import delifranceLogo from "../../images/logo2024/delifrance.png";
import ninJiOmLogo from "../../images/logo2024/nin-jiom.png";
import sharpLogo from "../../images/logo2024/sharp.png";
import tigerLogo from "../../images/logo2024/tiger.png";

import idealeChefLogo from "../../images/logo2024/ideale-chef.png";
import itsuLogo from "../../images/logo2024/itsu.png";
import miseEnSceneLogo from "../../images/logo2024/mes.png";
import purinaLogo from "../../images/logo2024/purina.png";
import zanussiLogo from "../../images/logo2024/zanussi.png";

import dieselLogo from "../../images/logo2024/diesel.png";
import guessLogo from "../../images/logo2024/guess.png";
import jamiesonLogo from "../../images/logo2024/jamieson.png";
import omekanzLogo from "../../images/logo2024/omekanz.png";
import hartmannLogo from "../../images/logo2024/paul-hartmann.png";

import helaSlimLogo from "../../images/logo2024/hela-slim.png";
import oenobiolLogo from "../../images/logo2024/oenobiol.png";
import ipsenLogo from "../../images/logo2024/smecta-ipsen.png";
import takedaLogo from "../../images/logo2024/takeda.png";
import waferSystemsLogo from "../../images/logo2024/wafer-systems.png";

import checkPointLogo from "../../images/logo2024/check-point.png";
import china3DLogo from "../../images/logo2024/china-3d.png";
import edkoLogo from "../../images/logo2024/edko.png";
import hkriLogo from "../../images/logo2024/hkri.png";
import icacLogo from "../../images/logo2024/icac.png";

import aqumonLogo from "../../images/logo2024/aqumon.png";
import benecolLogo from "../../images/logo2024/benecol.png";
import bondConnectLogo from "../../images/logo2024/bond-connect.png";
import kubeLogo from "../../images/logo2024/kube.png";
import boomLogo from "../../images/logo2024/monex-boom.jpeg";

import addictionLogo from "../../images/logo2024/addiction.png";
import chingJiangYuenLogo from "../../images/logo2024/ching-jiang-yuen.png";
import citraciumLogo from "../../images/logo2024/citracium.png";
import donSimonLogo from "../../images/logo2024/don-simon.png";
import soloLogo from "../../images/logo2024/solo.png";

import careLogo from "../../images/logo2024/care.png";
import aspenLogo from "../../images/logo2024/cortal.png";
import hkDanceLogo from "../../images/logo2024/hk-dance.png";
import hkSinfoniettaLogo from "../../images/logo2024/hk-sinfonietta.png";
import luHKLogo from "../../images/logo2024/lu-hk.png";

import ScrollDownButton from "../ScrollDownButton";

import { SectionProps } from "../../types/types";
const TOTAL_IMAGES = 25; // total 64, 34 hidden client logo

function WebClientSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const [moreClient, setMoreClient] = useState(false);
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    console.log("web banner section loaded images", loadedImages);
    if (loadedImages === TOTAL_IMAGES) {
      setLoadedSections((loadedSections) => loadedSections + 1);
    }
  }, [loadedImages]);
  return (
    <StyledSection
      id="client-section"
      style={{
        backgroundImage: `url(${bg3})`,
      }}
    >
      <Container maxWidth="xl">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12}>
            <SectionTitle>OUR CLIENT</SectionTitle>
          </Grid>
        </Grid>
      </Container>

      <InView>
        {({ ref, inView }) => (
          <Container maxWidth="xl">
            <Grid container justify="center" spacing={1}>
              <Grid item xs={12}>
                <motion.div
                  ref={ref}
                  initial={false}
                  animate={inView ? "visible" : "hidden"}
                  variants={clientLogoContainerVariants}
                >
                  {/* 5 x 6 line 1 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "1.2" }}
                      src={dahSingBankLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "1.4" }}
                      src={mtrLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={amoyLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "1.2" }}
                      src={trappistLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.8" }}
                      src={aromeLogo}
                    />
                  </motion.div>

                  {/* 5 x 6 line 2 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.65" }}
                      src={nestleLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.65" }}
                      src={taoTiLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "1.2" }}
                      src={electroluxLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={toshibaLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={nikonLogo}
                    />
                  </motion.div>

                  {/* 5 x 6 line 3 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={niveaLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.6" }}
                      src={dermaLabLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.75" }}
                      src={belifLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.85" }}
                      src={ryoLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.9" }}
                      src={tumiLogo}
                    />
                  </motion.div>

                  {/* 5 x 6 line 4 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={dysonLogo}
                    />

                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={tigerBalmLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.55" }}
                      src={mmmLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={speedtoxLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "1.2" }}
                      src={wingLungBankLogo}
                    />
                  </motion.div>

                  {/* 5 x 6 line 5 */}
                  <motion.div variants={clientLogoLineVariants}>
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      src={chinachemLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "1.2" }}
                      src={wangOnLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.65" }}
                      src={hendersonLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.65" }}
                      src={chineseEstatesLogo}
                    />
                    <ClientLogo
                      onLoad={() =>
                        setLoadedImages((loadedImages) => loadedImages + 1)
                      }
                      style={{ scale: "0.7" }}
                      src={chevalierLogo}
                    />
                  </motion.div>

                  {/* 5 x 6 line 6 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={freseniusLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={fehdLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.4" }}
                        src={hkuLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={mpfaLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={sky100Logo}
                      />
                    </motion.div>
                  )}

                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.1" }}
                        src={dohLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={vtcLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={hkfiLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={iaLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={covaLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 7 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.6" }}
                        src={wingWahLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={tsuiWahLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={heinekenLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={jackJillLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={panasonicLogo}
                      />
                    </motion.div>
                  )}

                  {/* new 5 x 6 line 8 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={whooLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.9" }}
                        src={eucerinLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={ftLifeLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={gardenLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={maximsLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 9 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={victorinoxLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={drKongLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={asusLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={ciscoLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={hitachiLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 10 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={juvedermLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={waiYuenTongLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={calcichewLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={laiSunLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={l2Logo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 11 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={chiefLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={fitchRatingsLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={commBankLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "1.2" }}
                        src={educationUniversityLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={fulumLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 12 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={uccLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.7" }}
                        src={ajiNoChimiLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={taikooLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.7" }}
                        src={almondRocaLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={hungFookTongLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 13 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={delifranceLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={tigerLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={sharpLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={coswayLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={ninJiOmLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 14 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={zanussiLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={itsuLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={idealeChefLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={purinaLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.7" }}
                        src={miseEnSceneLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 15 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={guessLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.65" }}
                        src={dieselLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.95" }}
                        src={hartmannLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={omekanzLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={jamiesonLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 16 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={oenobiolLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={takedaLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={helaSlimLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={ipsenLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={waferSystemsLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 17 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={checkPointLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={edkoLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={china3DLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.65" }}
                        src={icacLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.65" }}
                        src={hkriLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 18 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={benecolLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={aqumonLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.65" }}
                        src={boomLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.7" }}
                        src={bondConnectLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={kubeLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 19 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.65" }}
                        src={soloLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={chingJiangYuenLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={donSimonLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={addictionLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.85" }}
                        src={citraciumLogo}
                      />
                    </motion.div>
                  )}

                  {/* 5 x 6 line 20 (hidden) */}
                  {moreClient && (
                    <motion.div variants={clientLogoLineVariants}>
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={aspenLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={hkDanceLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={hkSinfoniettaLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        src={luHKLogo}
                      />
                      <ClientLogo
                        onLoad={() =>
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }
                        style={{ scale: "0.8" }}
                        src={careLogo}
                      />
                    </motion.div>
                  )}
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        )}
      </InView>

      <div
        style={{
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Button
          style={{
            color: "white",
            fontSize: "1.4rem",
            fontFamily: "HelveticaNeueBold",
          }}
          onClick={() => {
            setMoreClient(!moreClient);
            console.log("more client:", moreClient);
          }}
        >
          {moreClient ? "Less" : "More"}
        </Button>
        <ScrollDownButton
          goToSection="showcase-section"
          setActiveSection={setActiveSection}
        />
      </div>
    </StyledSection>
  );
}

export default WebClientSection;
