import React, { useState, useEffect, memo } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  IconButton,
  TextField,
  Snackbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import {
  StyledSection,
  SectionTitle,
  MobileStyledSection,
} from "../../styles/styles";
import bg4 from "../../images/backgrounds/bg-4.png";
import bg10 from "../../images/backgrounds/bg-10.png";
// data
import { AppConstants, EmailJs } from "../../data/appConstants";
import emailjs from "emailjs-com";
import { SectionProps } from "../../types/types";

import googlePartnerLogo from "../../images/google-partner-logo.jpg";
import hashtagLogo from "../../images/hashtag.png";
import agencyAward2022Logo from "../../images/agency-awards-2022-logo.png";
import markiesLogo from "../../images/markies-award.png";
import digizLogo from "../../images/digiz-bronze.jpg";
import mea2023Logo from "../../images/mea-award-2023.jpg";
import markies2024Logo from "../../images/markies-award-2024.jpg";

import { Link } from "react-router-dom";
const TOTAL_IMAGES = 0;

function WebContactUsSection({
  setActiveSection,
  setLoadedSections,
  loadedSections,
}: SectionProps) {
  const [loadedImages, setLoadedImages] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const sendEmail = async () => {
    const data = {
      name,
      email,
      contact,
      message,
    };
    try {
      const result = await emailjs.send(
        EmailJs.serviceId,
        EmailJs.templateId,
        data,
        EmailJs.userId
      );

      // log result
      console.log(result);
      setOpenSnackbar(true);

      // reset form
      setName("");
      setEmail("");
      setContact("");
      setMessage("");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("web contact us section loaded images", loadedImages);
    if (loadedImages === TOTAL_IMAGES) {
      setLoadedSections((loadedSections) => loadedSections + 1);
    }
  }, [loadedImages]);

  return (
    <section
      id="contact-us-section"
      style={{
        margin: 0,
        padding: 0,
        position: "relative",
        backgroundColor: "black",
        height: "100%",
        minHeight: "800px",
      }}
    >
      <Grid container style={{ height: "100%", minHeight: "800px" }}>
        <SectionTitle style={{ textAlign: "center", width: "100%" }}>
          {AppConstants.home.contact_us.en_title}
        </SectionTitle>
        <Grid container>
          {/* left pane - contact info */}
          <Grid item xs={7}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              wrap="nowrap"
              style={{
                height: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundImage: `url(${bg10})`,
              }}
            >
              <Box m={2} flexGrow="1"></Box>

              <Box m={2}>
                <Typography
                  variant="h4"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  {AppConstants.home.contact_us.en_tagline_1}
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  style={{
                    color: "rgba(0, 188, 228, 1)",
                    fontFamily: "HelveticaNeueBold",
                  }}
                >
                  {AppConstants.home.contact_us.en_tagline_2}
                </Typography>
              </Box>

              <Box m={2}>
                <Typography
                  variant="h6"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  {AppConstants.home.contact_us.en_phone}
                </Typography>
                <Typography
                  variant="h6"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  {AppConstants.home.contact_us.en_email}
                </Typography>
              </Box>

              <Box m={2}>
                <Typography
                  variant="h6"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  {AppConstants.home.contact_us.en_address_1}
                </Typography>
                <Typography
                  variant="h6"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  {AppConstants.home.contact_us.en_address_2}
                </Typography>
                <Typography
                  variant="h6"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  {AppConstants.home.contact_us.en_address_3}
                </Typography>
              </Box>

              <Box m={2} flexGrow="1"></Box>

              <Box m={2}>
                <Typography
                  variant="h6"
                  align="left"
                  style={{ color: "white", fontFamily: "HelveticaNeueBold" }}
                >
                  <Link
                    to={{
                      pathname:
                        "https://www.google.com/partners/agency?id=8265572971",
                    }}
                    target="_blank"
                  >
                    <img
                      src={googlePartnerLogo}
                      style={{
                        height: "60px",
                        margin: "10px",
                        // minWidth: "20px",
                        // marginTop: "auto",
                        // position: "absolute",
                        // bottom: "2rem",
                      }}
                      alt="google partner"
                    />
                  </Link>

                  <img
                    src={hashtagLogo}
                    style={{ height: "60px", margin: "10px" }}
                    alt="hashtag bronze winner"
                  />
                  <img
                    src={agencyAward2022Logo}
                    style={{ height: "60px", margin: "10px" }}
                    alt="Agency of the Year Award 2022"
                  />
                  <img
                    src={markiesLogo}
                    style={{ height: "60px", margin: "10px" }}
                    alt="Markies Award 2022"
                  />
                  <img
                    src={digizLogo}
                    style={{ height: "60px", margin: "10px" }}
                    alt="Digiz Award 2022"
                  />
                  <img
                    src={mea2023Logo}
                    style={{ height: "60px", margin: "10px" }}
                    alt="MEA Award 2023"
                  />
                  <img
                    src={markies2024Logo}
                    style={{ height: "60px", margin: "10px" }}
                    alt="Markies Award 2024"
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* right pane - email form */}
          <Grid item xs={5}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              wrap="nowrap"
              style={{ height: "80vh" }}
            >
              <Box m={3} style={{ marginTop: "10rem" }}>
                <form>
                  {/* name */}
                  <Box m={2}>
                    <TextField
                      id="name"
                      placeholder="NAME"
                      variant="outlined"
                      style={{
                        backgroundColor: "#fff",
                        width: "30vw",
                        borderRadius: "20px",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Box>

                  {/* email */}
                  <Box m={2}>
                    <TextField
                      id="email"
                      placeholder="EMAIL"
                      variant="outlined"
                      style={{
                        backgroundColor: "#fff",
                        width: "30vw",
                        borderRadius: "20px",
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>

                  {/* contact */}
                  <Box m={2}>
                    <TextField
                      id="contact"
                      placeholder="MOBILE"
                      variant="outlined"
                      style={{
                        backgroundColor: "#fff",
                        width: "30vw",
                        borderRadius: "20px",
                      }}
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </Box>

                  {/* message */}
                  <Box m={2}>
                    <TextField
                      id="message"
                      placeholder="MESSAGE"
                      variant="outlined"
                      style={{
                        backgroundColor: "#fff",
                        width: "30vw",
                        borderRadius: "20px",
                      }}
                      multiline
                      rows={10}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Box>
                  <Box m={3}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#fff",
                        width: "10vw",
                        borderRadius: "20px",
                      }}
                      disabled={!name || !contact || !message}
                      onClick={() => sendEmail()}
                    >
                      <Typography variant="body1" color="primary">
                        Send
                      </Typography>
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="Thank you for your request"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <CloseIcon color="secondary" fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </section>
  );
}

export default WebContactUsSection;
