import React, { useState } from "react";
import { Grid, IconButton, Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import rdsLogo from "../images/rds-logo.png";
import blossomsLogo from "../images/blossoms-logo-2.png";
import { motion } from "framer-motion";
// data
import { AppConstants } from "../data/appConstants";

const NavbarItemList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const NavbarItem = styled.li`
  width: 1vw;
`;

const NavbarItemContainerVariants = {
  hidden: { opacity: 0, transition: { duration: 1 } },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.1,
    },
  },
};

const NavbarItemVariants = {
  hidden: { opacity: 0, x: -50, transition: { duration: 0.5 } },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};
// export const StyledLink = styled(Link)`
//   margin: 1rem;
//   padding: 1rem;
//   font-size: 1.2rem;
//   text-decoration: none;
//   color: #e9bfa2;
//   transition: all 0.5s ease-out;
//   &:hover {
//     color: #fff;
//     transition: all 0.5s ease-out;
//   }
// `;

const MobileStyledNavLink = styled(NavLink)`
  margin: 0 auto;
  padding: 5%;
  width: 90%;
  height: 10vh;
  text-decoration: none;
  background-color: #000;
  color: rgba(0, 188, 228, 1);
  font-family: "HelveticaNeueCondenseBold";
  font-size: 1.4rem;
  text-align: center;
  overflow-x: hidden;
`;
const StyledNavLink = styled(NavLink)`
  margin: 1rem 0;
  padding: 0;
  width: 8vw;
  /* display: block; */
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1vw; // 1rem
`;

const StyledMotionDiv = styled(motion.div)`
  font-family: "HelveticaNeueBold";
  color: "#fff";
`;

function MobileNavbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    // <Grid style={{position: 'fixed' }} container direction="row" justify="space-between" alignItems="center">
    <>
      <Grid
        style={{
          position: "fixed",
          margin: 0,
          width: "100vw",
          height: "120px",
          zIndex: 1000,
          // backgroundColor: 'rgba(255,255,0,0.8)'
        }}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={6}>
          <NavLink
            to="/"
            // style={{
            //   height: "50px",
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
          >
            <img
              src={rdsLogo}
              style={{
                width: "100px",
                // maxWidth: "60px",
                margin: 0,
                padding: 0,
                zIndex: 1000,
              }}
            />
          </NavLink>
          <NavLink
            to="/"
            // style={{
            //   height: "50px",
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            // }}
          >
            <img
              src={blossomsLogo}
              style={{
                width: "60px",
                // maxWidth: "30px",
                margin: 0,
                padding: 0,
                // paddingTop: "-30px",
                zIndex: 1000,
                transform: "translate(0px, -10px)",
              }}
            />
          </NavLink>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs={3}>
          <IconButton
            onClick={() => setDrawerOpen(true)}
            style={{
              width: "5rem",
              height: "5rem",
              margin: 0,
              padding: 0,
              color: "#fff",
              transform: "translate(20px, -10px)",
            }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        style={{
          width: "90%",
          height: "100vh",
          margin: "0 auto",
          padding: 0,
          paddingTop: "15vh",
          backgroundColor: "#000000",
          overflowX: "hidden",
        }}
      >
        {/* <StyledLink to={"#"} onClick={googleSignIn}>
        {nav.login_zh}
      </StyledLink>
    
    {status == "success" && user && (
      <StyledLink to={nav.profile.to} onClick={closeDrawer}>
        {nav.profile.title_zh}
      </StyledLink>
    )} */}
        {/* <motion.div
          initial="hidden"
          animate="visible"
          variants={NavbarItemContainerVariants}
        > */}
        <MobileStyledNavLink to={""} onClick={() => setDrawerOpen(false)}>
          <p style={{ color: "white" }}>X</p>
        </MobileStyledNavLink>
        {AppConstants.navbar.nav_items.map((navItem) => (
          <MobileStyledNavLink
            to={navItem.link_to}
            onClick={() => setDrawerOpen(false)}
          >
            <StyledMotionDiv
              initial="hidden"
              animate="visible"
              variants={NavbarItemVariants}
            >
              {navItem.title_en}
            </StyledMotionDiv>
          </MobileStyledNavLink>
        ))}
        {/* </motion.div> */}
      </Drawer>
    </>
  );
}

export default MobileNavbar;
