export const AppConstants = {
  navbar: {
    nav_items: [
      { id: 0, title_en: "HOME", title_zh: "主頁", link_to: "/" },
      {
        id: 1,
        title_en: "ABOUT US",
        title_zh: "關於我們", 
        link_to: {
          pathname: "/about-us",
        },
      },
      {
        id: 2,
        title_en: "SOLUTIONS",
        title_zh: "服務範疇",
        link_to: {
          pathname: "/",
          hash: "solution-section",
        },
      },
      {
        id: 3,
        title_en: "OUR CLIENT",
        title_zh: "客戶",
        link_to: {
          pathname: "/",
          hash: "#client-section",
        },
      },
      { id: 4, title_en: "SHOWCASE", title_zh: "案例", link_to: "/showcase" },
      {
        id: 5,
        title_en: "CONTACT US",
        title_zh: "聯絡我們",
        // link_to: "/contact-us",
        link_to: {
          pathname: "/",
          hash: "#contact-us-section",
        },
      },
    ],
  },
  home: {
    banner: {},
    solution: {
      en_title: "SOLUTION",
      media: {
        en_title: ["MEDIA", "STRATEGY", "& PLANNING"],
        en_content: [
          "Campaign Management & Optimization",
          "Programmatic Solutions",
          "Media Plan and Buy",
          "SEM",
        ],
      },
      creative: {
        en_title: ["CREATIVE", "& IDEATION"],
        en_content: ["Creative Development", "KV & Video Production"],
      },
      social: {
        en_title: ["SOCIAL", "MARKETING"],
        en_content: [
          "Social Content Management",
          "Influencers Management",
          "Campaign Activation",
        ],
      },
    },
    our_client: {
      en_title: "OUR CLIENT",
    },
    showcase: {
      en_title: "SHOWCASE",
      portfolio: [

        {
          id: 1,
          en_title: "Trappist",
          taglines: [
            "CREATIVE CONCEPT DEVELOPMENT",
            "VIDEO & KV PRODUCTION",
            "ONLINE & OFFLINE INTEG RATED MEDIA PLANNING & EXECUTION",
          ],
          en_tagline_1: "CREATIVE CONCEPT DEVELOPMENT",
          en_tagline_2: "VIDEO & KV PRODUCTION",
          en_tagline_3: "ONLINE & OFFLINE INTEG RATED MEDIA PLANNING & EXECUTION",
          imageURL:
            "https://storage.googleapis.com/rds-web-demo.appspot.com/New_Project_cc8ce1c9b1.png",
        },
        {
          id: 2,
          en_title: "Amoy",
          taglines: [
            "CREATIVE CONCEPT DEVELOPMENT",
            "VIDEO & KV PRODUCTION",
            "CELEBRITY MANAGEMENT",
            "ONLINE & OFFLINE MEDIA PLAN & BUY"
          ],
          en_tagline_1: "CREATIVE CONCEPT DEVELOPMENT",
          en_tagline_2: "VIDEO & KV PRODUCTION",
          en_tagline_3: "CELEBRITY MANAGEMENT",
          imageURL:
            "https://storage.googleapis.com/rds-web-demo.appspot.com/New_Project_93454a11b9.png",
        },
        {
          id: 3,
          en_title: "Calcichew",
          taglines: [
            "CREATIVE CONCEPT DEVELOPMENT",
            "VIDEO & KV PRODUCTION",
            "CELEBRITY MANAGEMENT",
            "SOCIAL CONTENT CREATION",
            "ONLINE & OFFLINE MEDIA PLAN & BUY"
          ],
          en_tagline_1: "CREATIVE CONCEPT DEVELOPMENT",
          en_tagline_2: "VIDEO & KV PRODUCTION",
          en_tagline_3: "CELEBRITY MANAGEMENT",
          imageURL:
            "https://storage.googleapis.com/rds-web-demo.appspot.com/New_Project2_dd682a5019.png",
        },
        // {
        //   id: 1,
        //   en_title: "AMOY",
        //   taglines: [
        //     "KEY VISUAL DEVELOPMENT",
        //     "SOCIAL CONTENT CREATION",
        //     "DIGITAL MEDIA PLAN & BUY",
        //   ],
        //   en_tagline_1: "KEY VISUAL DEVELOPMENT",
        //   en_tagline_2: "SOCIAL CONTENT CREATION",
        //   en_tagline_3: "DIGITAL MEDIA PLAN & BUY",
        //   imageURL:
        //     "https://firebasestorage.googleapis.com/v0/b/rds-web-demo.appspot.com/o/Showcase%2FAmoy%2F2.jpg?alt=media&token=fc953557-9254-4c6e-ae92-93de29c58184",
        // },
        // {
        //   id: 2,
        //   en_title: "Dr. Kong",
        //   taglines: [
        //     "KEY VISUAL DEVELOPMENT",
        //     "VIDEO PRODUCTION",
        //     "SOCIAL CONTENT DEVELOPMENT",
        //     "DIGITAL MEDIA PLAN AND BUY",
        //   ],
        //   en_tagline_1: "KEY VISUAL DEVELOPMENT",
        //   en_tagline_2: "VIDEO PRODUCTION",
        //   en_tagline_3: "SOCIAL CONTENT DEVELOPMENT",
        //   imageURL:
        //     "https://firebasestorage.googleapis.com/v0/b/rds-web-demo.appspot.com/o/Showcase%2FDrKong%2F1.jpg?alt=media&token=36247b64-4b0a-4a46-ace5-2d0e4c849627",
        // },
        // {
        //   id: 3,
        //   en_title: "Eucerin",
        //   taglines: [
        //     "KEY VISUAL DEVELOPMENT",
        //     "SOCIAL CONTENT DEVELOPMENT",
        //     "DIGITAL MEDIA PLAN AND BUY",
        //     "PUBLISHER & INFLUENCER MANAGEMENT",
        //   ],
        //   en_tagline_1: "KEY VISUAL DEVELOPMENT",
        //   en_tagline_2: "SOCIAL CONTENT DEVELOPMENT",
        //   en_tagline_3: "DIGITAL MEDIA PLAN AND BUY",
        //   imageURL:
        //     "https://firebasestorage.googleapis.com/v0/b/rds-web-demo.appspot.com/o/Showcase%2FEucerin%2F1.jpg?alt=media&token=28c75e3c-fb38-467c-8705-a8accb022891",
        // },
      ],
    },
    contact_us: {
      en_title: "CONTACT US",
      en_tagline_1: "FEEL FREE TO",
      en_tagline_2: "CONTACT US",
      en_phone: "TEL: 852 - 3110 3818",
      en_email: "info@rds.com.hk",
      en_address_1: "Unit 02-03, 21/F, Midas Plaza,",
      en_address_2: "No.1 Tai Yau Street, San Po Kong",
      en_address_3: "Kowloon, HK",
    },
  },
  about_us: {
    section_1: {
      create_box: {
        title_en: "CREATE",
        title_zh: "創造",
        sub_title_en: ["Unique", "Brand Message"],
        sub_title_zh: "創造獨特的品牌訊息",
      },
      channel_box: {
        title_en: "CHANNEL",
        title_zh: "數碼化營銷",
        sub_title_en: ["Your Marketing", "Messages Digitally"],
        sub_title_zh: "以數碼化方式傳遞營銷訊息",
      },
      connect_box: {
        title_en: "CONNECT",
        title_zh: "聯繫",
        sub_title_en: ["With Your Audience"],
        sub_title_zh: "聯繫品牌與受眾",
      },
    },
    section_2: {
      title_en: ["TALENTS CONVERGE.", "BREAKTHROUGH ACHIEVED."],
      title_zh: "人才匯聚 實現突破",
      content_en: [
        "RDS DIGITAL is a team of passionate, professional experts to ensure the provision of best-in-class marketing disciplines seamlessly integrated and flexibly deployed to meet clients' needs. By synergizing talents in different fields, RDS Digital provides lcients with a comprehensive suite of highly effective digital solutions to fulfil clients' business ambition through our unique 3R model.",
        "Providing holistic digital solutions to magnify clients' digital presence and social impacts, we are your trusted partner to navigate the digital world.",
      ],
      content_zh: [
        "RDS DIGITAL 由不同領域的業內人才組成，",
        "致力整合不同，",
        "RDS DIGITAL 由不同領域的業內人才組成，",
        "RDS DIGITAL 由不同領域的業內人才組成，",
        "RDS DIGITAL 由不同領域的業內人才組成，",
      ],
    },
    section_3: {
      responsive_box: {
        title_en: "RESPONSIVE",
        title_zh: "敏銳",
        sub_title_en: [
          "We React Fast To Client And",
          "The Latest Market",
          "Happenings And Trends",
        ],
        sub_title_zh: ["留意巿場動態，", "洞悉巿場趨勢，", "迅速回應客戶需求"],
      },
      reliable_box: {
        title_en: "",
        title_zh: "",
        sub_title_en: [
          "We Formulate On-targeted,",
          "Insight-led",
          "Digital Strategies",
        ],
        sub_title_zh: ["洞悉巿場需求，", "制定具有針對性的營銷策略"],
      },
      relevant_box: {
        title_en: "",
        title_zh: "",
        sub_title_en: [
          "We Understand",
          "The Needs Of Audience",
          "And Curate Content",
          "Relevant To Them",
        ],
        sub_title_zh: ["了解受眾需求，", "策劃與受眾相關的內容"],
      },
    },
  },
  showcase: {
    title_en: "SHOWCASE",
    title_zh: "案例",
    categories: [
      { id: 0, title_en: "ALL", title_zh: "全部", index: "all" },
      {
        id: 1,
        title_en: "HOLLISTIC CAMPAIGN",
        title_zh: "創意構思",
        index: "hollistic-campaign",
      },
      { id: 2, title_en: "SOCIAL", title_zh: "社交媒體內容", index: "social" },
      {
        id: 3,
        title_en: "INFLUENCER",
        title_zh: "媒體策劃",
        index: "influencer",
      },
      { id: 4, title_en: "IDEATION", title_zh: "廣告活動", index: "ideation" },
    ],
  },
};

export interface Section {
  id: string;
}

export const HomeSections: Section[] = [
  { id: "banner-section" },
  { id: "solution-section" },
  { id: "client-section" },
  { id: "showcase-section" },
  { id: "contact-us-section" },
];

export const Clients = [
  // line 1
  { id: 1, line: 1, name: "Amoy", image: "amoy.png" },
  { id: 2, line: 1, name: "Knife", image: "knife.png" },
  { id: 3, line: 1, name: "Trappist", image: "trappist.png" },
  { id: 4, line: 1, name: "Maxim's", image: "maxims.png" },
  { id: 5, line: 1, name: "Arome", image: "arome.png" },
  { id: 6, line: 1, name: "Taipan", image: "taipan.png" },
  { id: 7, line: 1, name: "Kee Wah Bakery", image: "kee-wah-bakery.png" },
  { id: 8, line: 1, name: "Wing Wah", image: "wing-wah.png" },

  // line 2
  { id: 9, line: 2, name: "Nissin", image: "nissin.png" },
  { id: 10, line: 2, name: "Jack n Jill", image: "jack-jill.png" },
  { id: 11, line: 2, name: "Hung Fook Tong", image: "hung-fook-tong.png" },
  { id: 12, line: 2, name: "Delifrance", image: "delifrance.png" },
  { id: 13, line: 2, name: "Garden", image: "garden.png" },
  { id: 14, line: 2, name: "Meatogo", image: "meatogo.png" },
  { id: 15, line: 2, name: "Almond Roca", image: "almond-roca.png" },
  { id: 16, line: 2, name: "UCC", image: "ucc.png" },

  // line 3
  { id: 17, line: 3, name: "Chinachem Group", image: "chinachem-group.png" },
  { id: 18, line: 3, name: "Wang On Properties", image: "wang-on.png" },
  { id: 19, line: 3, name: "Lai Sun Group", image: "lai-sun.png" },
  { id: 20, line: 3, name: "Chevalier Group", image: "chevalier-group.png" },
  {
    id: 21,
    line: 3,
    name: "Hong Kong Sinfonietta",
    image: "hong-kong-sinfonietta.png",
  },
  {
    id: 22,
    line: 3,
    name: "Hong Kong Dance Company",
    image: "hong-kong-dance.png",
  },
  { id: 23, line: 3, name: "EDKO Films", image: "edko.png" },
  { id: 24, line: 3, name: "China 3D Digital", image: "china-3d.png" },

  // line 4
  { id: 25, line: 4, name: "Tumi", image: "tumi.png" },
  { id: 26, line: 4, name: "Diesel", image: "diesel.png" },
  { id: 27, line: 4, name: "Guess", image: "guess.png" },
  { id: 28, line: 4, name: "Dr Kong", image: "dr-kong.png" },
  { id: 29, line: 4, name: "Addiction", image: "addiction.jpg" },
  { id: 30, line: 4, name: "Eucerin", image: "eucerin.png" },
  { id: 31, line: 4, name: "Nivea", image: "nivea.png" },
  { id: 32, line: 4, name: "Juvederm", image: "juvederm.png" },

  // line 5
  { id: 33, line: 5, name: "Hitachi", image: "hitachi.png" },
  { id: 34, line: 5, name: "Panasonic", image: "panasonic.png" },
  { id: 35, line: 5, name: "Electrolux", image: "electrolux.png" },
  { id: 36, line: 5, name: "General", image: "general.png" },
  { id: 37, line: 5, name: "Asus", image: "asus.png" },
  { id: 38, line: 5, name: "Casio", image: "casio.png" },
  { id: 39, line: 5, name: "Cisco", image: "cisco.png" },
  { id: 40, line: 5, name: "Wafer Systems", image: "wafer-systems.png" },

  // line 6
  { id: 41, line: 6, name: "念慈菴", image: "nin-ji-om.jpg" },
  { id: 42, line: 6, name: "位元堂", image: "wai-yuen-tong.png" },
  { id: 43, line: 6, name: "Luxembourg Medicine", image: "luxembourg.jpg" },
  { id: 44, line: 6, name: "Tiger Balm", image: "tiger-balm.png" },
  { id: 45, line: 6, name: "Fresenius Medical Care", image: "fresenius.jpg" },
  { id: 46, line: 6, name: "OENOBIOL", image: "oenobiol.png" },
  { id: 47, line: 6, name: "Takeda", image: "takeda.png" },
  { id: 48, line: 6, name: "Hela Slim", image: "hela-slim.png" },

  // line 7
  { id: 49, line: 7, name: "Department of Health", image: "doh.png" },
  {
    id: 50,
    line: 7,
    name: "Food and Environmental Hygiene Department",
    image: "fehd.png",
  },
  {
    id: 51,
    line: 7,
    name: "Mandatory Provident Fund Schemes Authority",
    image: "mpfa.png",
  },
  { id: 52, line: 7, name: "Art Promotion Office", image: "apo.jpg" },
  { id: 53, line: 7, name: "Insurance Authority", image: "ia.png" },
  {
    id: 54,
    line: 7,
    name: "The Hong Kong Federation of Insurers",
    image: "hkfi.png",
  },
  { id: 55, line: 7, name: "The University of Hong Kong", image: "hku.png" },
  {
    id: 56,
    line: 7,
    name: "The Education University of Hong Kong",
    image: "education-university.png",
  },

  // line 8
  { id: 57, line: 8, name: "Taikoo", image: "taikoo.png" },
  { id: 58, line: 8, name: "Jade Garden", image: "jade-garden.png" },
  { id: 59, line: 8, name: "清淨園", image: "ching-jiang-yuen.png" },
  { id: 60, line: 8, name: "Fortress", image: "fortress.png" },
  { id: 61, line: 8, name: "Ideale Chef", image: "ideale-chef.png" },
  { id: 62, line: 8, name: "綠習慣", image: "green.png" },
  { id: 63, line: 8, name: "Mr Max", image: "dr-max.png" },
];

export const EmailJs = {
  serviceId: "service_azmh91q",
  templateId: "template_k8focli",
  userId: "user_y3HZT3zcoiKw9SYYYezPe",
};

export const firebaseConfig = {
  apiKey: "AIzaSyAqDJelWqr9VG3kmNUMkcraGUH_AyFmHSE",
  authDomain: "rds-web-demo.firebaseapp.com",
  projectId: "rds-web-demo",
  storageBucket: "rds-web-demo.appspot.com",
  messagingSenderId: "659105031030",
  appId: "1:659105031030:web:e32182d93b444c38a69057",
};

export const firebaseConstants = {
  SHOWCASE_REF: "showcase",
};

export const herokuBaseUrl = "shielded-reaches-08101.herokuapp.com";
