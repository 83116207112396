import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledSection = styled.section`
  margin: 0;
  padding: 0;

  position: relative;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MobileStyledSection = styled.section`
  margin: 0;
  padding: 0;

  position: relative;
  width: 100vw;
  /* height: 50vh;
  min-height: 50vh; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
`;

export const MobileSectionTitle = styled.h1`
  padding-top: 5rem;
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
  color: rgba(0, 188, 228, 1);
  /* border-bottom: 1px solid rgba(0,188,228,1);
  width: 200px;
    padding: 0 0 4px; */
  &::after {
    display: block;
    position: absolute;
    content: "";
    border-top: 3px solid rgba(0, 188, 228, 1);
    width: 4rem;
    left: 50%;
    transform: translate(-50%, 0.2rem);
  }
`;

export const SectionTitle = styled.h1`
  padding-top: 2rem;
  margin: 0;
  font-size: 1.8rem;
  text-align: center;
  color: rgba(0, 188, 228, 1);
  /* border-bottom: 1px solid rgba(0,188,228,1);
  width: 200px;
    padding: 0 0 4px; */
  &::after {
    display: block;
    position: absolute;
    content: "";
    border-top: 3px solid rgba(0, 188, 228, 1);
    width: 4rem;
    left: 50%;
    transform: translate(-50%, 0.2rem);
  }
`;

export const MobileStyledH1 = styled.h1`
  text-align: left;
  padding-top: 5rem;
  padding-bottom: 2rem;
  margin-left: 5rem;
  /* color: rgba(0, 188, 228, 1); */
  font-family: "HelveticaNeueCondenseBold";
  font-size: 1rem;
  &::before {
    display: block;
    position: absolute;

    content: "";
    border-top: 2px solid rgba(0, 188, 228, 1);
    width: 5rem;
    margin-left: 5rem;
    /* left: 50%; */
    transform: translate(-100%, -1rem);
  }
`;

export const StyledH1 = styled.h1`
  text-align: left;
  padding-top: 20rem;
  padding-bottom: 2rem;
  margin-left: 4rem;
  /* color: rgba(0, 188, 228, 1); */
  font-family: "HelveticaNeueCondenseBold";
  font-size: 3rem;
  &::before {
    display: block;
    position: absolute;

    content: "";
    border-top: 3px solid rgba(0, 188, 228, 1);
    width: 5rem;
    margin-left: 5rem;
    /* left: 50%; */
    transform: translate(-100%, -0.5rem);
  }
`;

export const StyledShowcaseText = styled.h1`
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-left: 5rem;
  color: #fff;
  font-family: "HelveticaNeueCondenseBold";
`;

export const StyledBoxTitle = styled.h1`
  text-align: center;
  padding-top: 20rem;
  padding-bottom: 2rem;
  color: #fff;

  &::after {
    display: block;
    position: absolute;

    content: "";
    border-top: 3px solid rgba(255, 255, 255, 1);
    width: 6rem;
    margin-left: 6rem;
    transform: translate(-100%, -1rem);
  }
`;

export const MobileStyledShowcaseContent = styled.p`
  font-size: 1.2rem;
  text-align: left;
  margin-left: 2rem;
  line-height: 0.8rem;
`;

export const StyledShowcaseContent = styled.p`
  font-size: 1rem;
  text-align: left;
  margin-left: 4rem;
  line-height: 0.6rem;
`;

export const MobileFadeInLetter = styled(motion.img)`
  width: 8vw;
  z-index: 1000;
`;

export const FadeInLetter = styled(motion.img)`
  width: 5vw;
  z-index: 1000;
`;

export const MobileClientLogo = styled(motion.img)`
  width: 20vw;
  height: 20vw;
  object-fit: contain;
  padding: 1vw;
  margin: 2vw;
`;

export const ClientLogo = styled(motion.img)`
  width: 8vw;
  height: 8vw;
  object-fit: contain;
  padding: 2vw;
  margin: 1vw;
`;

export const MobileShowcasePoster = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ShowcasePoster = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ShowcasePosterTitle = motion(StyledH1);

export const ShowcaseBackdropTitle = styled.p`
  font-family: "HelveticaNeueCondenseBold";
  /* font-size: 2rem; */
  color: rgba(0, 188, 228, 1);
  text-align: left;
  /* border-top: 1px solid rgba(0, 188, 228, 1); */
  text-decoration-line: overline;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
`;

export const BackdropContentContainer = styled.div`
  position: relative;
  width: 90%;
  margin-top: 100vh;
  padding-top: 5rem;
  background-color: #000;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ShowcaseBackdropContent = styled.p`
  font-family: "HelveticaNeueCondenseBold";
  color: #fff;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;
