import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import {
  StyledSection,
  SectionTitle,
  StyledH1,
  StyledShowcaseContent,
  FadeInLetter,
  ClientLogo,
  ShowcasePoster,
  StyledBoxTitle,
  ShowcaseBackdropTitle,
  ShowcaseBackdropContent,
  MobileStyledSection,
} from "../../styles/styles";
import { Link } from "react-router-dom";
import bg1 from "../../images/backgrounds/about-us-bg-1.png";
import bg2 from "../../images/backgrounds/about-us-bg-2.png";
import bg3 from "../../images/backgrounds/about-us-bg-3.png";

import createImage from "../../images/icons/create.png";
import channelImage from "../../images/icons/channel.png";
import connectImage from "../../images/icons/connect.png";

import founderLeftImage from "../../images/icons/founder-left.png";
import founderCenterImage from "../../images/icons/founder-center.png";
import founderRightImage from "../../images/icons/founder-right.png";

import responsiveImage from "../../images/icons/responsive.png";
import reliableImage from "../../images/icons/reliable.png";
import relevantImage from "../../images/icons/relevant.png";

// components
import Navbar from "../../components/Navbar";
import MobileNavbar from "../../components/MobileNavbar";
import Footer from "../../components/Footer";

// data
import { AppConstants } from "../../data/appConstants";

// style
import styled from "styled-components";
import { motion } from "framer-motion";

import openQuote from "../../images/icons/open-quote.png";
import closeQuote from "../../images/icons/close-quote.png";
import LoadingLogo from "../../components/LoadingLogo";

import { InView } from "react-intersection-observer";
import ScrollDownButton from "../../components/ScrollDownButton";

// framer motion variants
const founderLeftLogoVariants = {
  hidden: {
    scale: 0,
    rotate: 120,
    x: -400,
    y: -400,
    transition: { duration: 1 },
  },
  visible: {
    scale: 1,
    rotate: 0,
    x: 0,
    y: 0,
    transition: { delay: 0.1, duration: 0.7 },
  },
};

const founderCenterLogoVariants = {
  hidden: {
    scale: 0,
    rotate: 120,
    x: -200,
    y: 200,
    transition: { duration: 1 },
  },
  visible: {
    scale: 1,
    rotate: 0,
    x: 0,
    y: 0,
    transition: { delay: 0.3, duration: 0.7 },
  },
};

const founderRightLogoVariants = {
  hidden: {
    scale: 0,
    rotate: 120,
    x: 400,
    y: 400,
    transition: { duration: 1 },
  },
  visible: {
    scale: 1,
    rotate: 0,
    x: 0,
    y: 0,
    transition: { delay: 0.5, duration: 0.7 },
  },
};

const MobileStyledBoxImage = styled.img`
  padding-top: 5rem;
  width: 80%;
`;

const StyledBoxImage = styled.img`
  /* padding-top: 5rem; */
  width: 80%;
`;

const BoxBlueText = styled.p`
  position: "absolute";
  margin: 0;
  padding: 0 2rem;
  font-family: "HelveticaNeueCondenseBold";
  font-size: 1.2rem;
  color: rgba(0, 188, 228, 1);
  transform: translateY(-40px);
`;

const MobileBoxBlueText = styled.p`
  position: "absolute";
  margin: 0;
  padding: 0 2rem;
  font-family: "HelveticaNeueCondenseBold";
  font-size: 1.4rem;
  color: rgba(0, 188, 228, 1);
  transform: translateY(-40px);
`;

const StyledP = styled.p`
  color: white;
  width: 50vw;
  margin: 1rem auto;
  text-align: justify;
  font-size: 1.1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: gray;
  margin: 1rem;
`;

function WebAboutUsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);
  const [activeSection, setActiveSection] = useState<string>("section-1");

  useEffect(() => {
    console.log("loaded images", loadedImages);
    if (loadedImages == 9) {
      setIsLoading(false);
    }
  }, [loadedImages]);

  return (
    <div>
      <div
        className={isLoading ? "show" : "hidden"}
        style={{ width: "100vw", height: "100vh", backgroundColor: "black" }}
      >
        <div
          style={{
            width: "50%",
            height: "50%",
            margin: 0,
            padding: 0,
            position: "absolute",
            // left: "50%",
            // top: "50%",
            transform: "translate(50%, 50%)",
            overflow: "hidden",
          }}
        >
          <LoadingLogo />
        </div>
      </div>
      <div className={isLoading ? "hidden" : "show"}>
        <StyledSection
          id="section-1"
          style={{
            backgroundImage: `url(${bg1})`,
          }}
        >
          <Navbar />
          <Container maxWidth="lg">
            <div
              style={{
                display: "grid",
                height: "100vh",
                placeItems: "center",
              }}
            >
              <Grid container justify="center" spacing={3}>
                <Grid item xs={10} sm={4}>
                  <StyledBoxImage
                    src={createImage}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  {AppConstants.about_us.section_1.create_box.sub_title_en.map(
                    (text) => (
                      <BoxBlueText style={{ fontSize: "2rem" }}>
                        {text}
                      </BoxBlueText>
                    )
                  )}
                </Grid>
                <Grid item xs={10} sm={4}>
                  <StyledBoxImage
                    src={channelImage}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  {AppConstants.about_us.section_1.channel_box.sub_title_en.map(
                    (text) => (
                      <BoxBlueText style={{ fontSize: "2rem" }}>
                        {text}
                      </BoxBlueText>
                    )
                  )}
                </Grid>
                <Grid item xs={10} sm={4}>
                  <StyledBoxImage
                    src={connectImage}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  {AppConstants.about_us.section_1.connect_box.sub_title_en.map(
                    (text) => (
                      <BoxBlueText style={{ fontSize: "2rem" }}>
                        {text}
                      </BoxBlueText>
                    )
                  )}
                </Grid>
                <div
                  style={{
                    position: "absolute",
                    bottom: "5vh",
                    left: "50%",
                    transform: "translate(-50%, 65%)",
                  }}
                >
                  <ScrollDownButton
                    goToSection="section-2"
                    setActiveSection={setActiveSection}
                  />
                </div>
              </Grid>
            </div>
          </Container>
        </StyledSection>

        <StyledSection
          id="section-2"
          style={{
            backgroundImage: `url(${bg2})`,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container maxWidth="lg">
            <div style={{ position: "absolute", left: "5vw", top: "5rem" }}>
              <img src={openQuote} style={{ width: "10rem" }} />
            </div>
            <div style={{ position: "absolute", right: "5vw", bottom: "5rem" }}>
              <img src={closeQuote} style={{ width: "10rem" }} />
            </div>
            <div
              style={{
                display: "grid",
                height: "100vh",
                placeItems: "center",
              }}
            >
              <InView>
                {({ ref, inView }) => (
                  <Grid container justify="center" spacing={3} ref={ref}>
                    <Grid item xs={10} sm={4}>
                      <motion.div
                        variants={founderLeftLogoVariants}
                        animate={inView ? "visible" : "hidden"}
                      >
                        <StyledBoxImage
                          src={founderLeftImage}
                          onLoad={() =>
                            setLoadedImages((loadedImages) => loadedImages + 1)
                          }
                        />
                      </motion.div>
                    </Grid>
                    <Grid item xs={10} sm={4}>
                      <motion.div
                        variants={founderCenterLogoVariants}
                        animate={inView ? "visible" : "hidden"}
                      >
                        <StyledBoxImage
                          src={founderCenterImage}
                          onLoad={() =>
                            setLoadedImages((loadedImages) => loadedImages + 1)
                          }
                        />
                      </motion.div>
                    </Grid>
                    <Grid item xs={10} sm={4}>
                      <motion.div
                        variants={founderRightLogoVariants}
                        animate={inView ? "visible" : "hidden"}
                      >
                        <StyledBoxImage
                          src={founderRightImage}
                          onLoad={() =>
                            setLoadedImages((loadedImages) => loadedImages + 1)
                          }
                        />
                      </motion.div>
                    </Grid>
                    <Box m={5}></Box>
                    <Grid item xs={10}>
                      <Typography
                        variant="h4"
                        style={{ color: "rgba(0, 188, 228, 1)" }}
                      >
                        <strong>
                          {AppConstants.about_us.section_2.title_en[0]}
                        </strong>
                      </Typography>
                      <Typography variant="h4" style={{ color: "white" }}>
                        <strong>
                          {AppConstants.about_us.section_2.title_en[1]}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledP>
                        <span style={{ color: "rgba(0, 188, 228, 1)" }}>
                          <strong>RDS DIGITAL </strong>
                        </span>
                        is a team of passionate, professional experts to ensure
                        the provision of best-in-class marketing disciplines
                        seamlessly integrated and flexibly deployed to meet
                        clients' needs. By synergizing talents in different
                        fields, RDS Digital provides clients with a
                        comprehensive suite of highly effective digital
                        solutions to fulfill clients' business ambition through
                        our unique 3R model.
                      </StyledP>
                      <StyledP>
                        Providing holistic digital solutions to magnify clients'
                        digital presence and social impacts, we are your trusted
                        partner to navigate the digital world.
                      </StyledP>
                    </Grid>
                  </Grid>
                )}
              </InView>
              <div
                style={{
                  position: "absolute",
                  bottom: "5vh",
                  left: "50%",
                  transform: "translate(-50%, 65%)",
                }}
              >
                <ScrollDownButton
                  goToSection="section-3"
                  setActiveSection={setActiveSection}
                />
              </div>
            </div>
          </Container>
        </StyledSection>

        <StyledSection
          id="section-3"
          style={{
            backgroundImage: `url(${bg3})`,
          }}
        >
          <Container maxWidth="lg">
            <div
              style={{
                display: "grid",
                height: "100vh",
                placeItems: "center",
              }}
            >
              <Grid container justify="center" spacing={3}>
                <Grid item xs={10} sm={4}>
                  <StyledBoxImage
                    src={responsiveImage}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  {AppConstants.about_us.section_3.responsive_box.sub_title_en.map(
                    (text) => (
                      <BoxBlueText>{text}</BoxBlueText>
                    )
                  )}
                </Grid>
                <Grid item xs={10} sm={4}>
                  <StyledBoxImage
                    src={reliableImage}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  {AppConstants.about_us.section_3.reliable_box.sub_title_en.map(
                    (text) => (
                      <BoxBlueText>{text}</BoxBlueText>
                    )
                  )}
                </Grid>
                <Grid item xs={10} sm={4}>
                  <StyledBoxImage
                    src={relevantImage}
                    onLoad={() =>
                      setLoadedImages((loadedImages) => loadedImages + 1)
                    }
                  />
                  {AppConstants.about_us.section_3.relevant_box.sub_title_en.map(
                    (text) => (
                      <BoxBlueText>{text}</BoxBlueText>
                    )
                  )}
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      color: "gray",
                      position: "absolute",
                      bottom: "3rem",
                      width: "100%",
                      margin: "1rem auto",
                    }}
                  >
                    <StyledLink to="/about-us">ABOUT US</StyledLink>|
                    <StyledLink to="/#solution-section">SOLUTIONS</StyledLink>|
                    <StyledLink to="/#client-section">OUR CLIENT</StyledLink>|
                    <StyledLink to="/showcase">SHOWCASE</StyledLink>|
                    <StyledLink to="/#contact-us-section">
                      CONTACT US
                    </StyledLink>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Container>
        </StyledSection>

        <Footer />
      </div>
    </div>
  );
}

export default WebAboutUsPage;
