import React, { Dispatch, SetStateAction } from "react";

import { useTheme, useMediaQuery } from "@material-ui/core";
import WebShowcaseNavbar from "./web/WebShowcaseNavbar";
import MobileShowcaseNavbar from "./mobile/MobileShowcaseNavbar";
import { CategoryType } from "../types/types";

interface ShowcaseNavbarProps {
  category: string;
  setCategory: Dispatch<SetStateAction<CategoryType>>;
}

function ShowcaseNavbar({ category, setCategory }: ShowcaseNavbarProps) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  return mobileView ? (
    <MobileShowcaseNavbar category={category} setCategory={setCategory} />
  ) : (
    <WebShowcaseNavbar category={category} setCategory={setCategory} />
  );
}

export default ShowcaseNavbar;
