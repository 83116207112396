import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  StyledSection,
  SectionTitle,
  StyledH1,
  StyledShowcaseContent,
  FadeInLetter,
  ClientLogo,
  ShowcasePoster,
  StyledBoxTitle,
  MobileStyledSection,
} from "../../styles/styles";
import {
  Container,
  Grid,
  Typography,
  useTheme,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import styled from "styled-components";
import Footer from "../../components/Footer";

import MobileNavbar from "../../components/MobileNavbar";
import bg1 from "../../images/backgrounds/about-us-bg-1.png";
import bg2 from "../../images/backgrounds/about-us-bg-2.png";
import bg3 from "../../images/backgrounds/about-us-bg-3.png";

import createImage from "../../images/icons/create.png";
import channelImage from "../../images/icons/channel.png";
import connectImage from "../../images/icons/connect.png";

import founderLeftImage from "../../images/icons/founder-left.png";
import founderCenterImage from "../../images/icons/founder-center.png";
import founderRightImage from "../../images/icons/founder-right.png";

import responsiveImage from "../../images/icons/responsive.png";
import reliableImage from "../../images/icons/reliable.png";
import relevantImage from "../../images/icons/relevant.png";
import { AppConstants } from "../../data/appConstants";

const MobileStyledBoxImage = styled.img`
  padding-top: 5rem;
  width: 80%;
`;
const MobileBoxBlueText = styled.p`
  position: "absolute";
  margin: 0;
  padding: 0 2rem;
  font-family: "HelveticaNeueCondenseBold";
  font-size: 1.4rem;
  color: rgba(0, 188, 228, 1);
  transform: translateY(-40px);
`;

const StyledP = styled.p`
  color: white;
  width: 50vw;
  margin: 1rem auto;
  text-align: justify;
  font-size: 1.1rem;
`;

const MobileStyledP = styled(StyledP)`
  width: 80vw;
  font-size: 1rem;
`;
function MobileAboutUsPage() {
  return (
    <>
      <MobileStyledSection
        id="m-section-1"
        style={{
          // height: "100vh",
          backgroundImage: `url(${bg1})`,
        }}
      >
        <MobileNavbar />
        <Grid container justify="center" spacing={1}>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={createImage} />
            {AppConstants.about_us.section_1.create_box.sub_title_en.map(
              (text) => (
                <MobileBoxBlueText>{text}</MobileBoxBlueText>
              )
            )}
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={channelImage} />
            {AppConstants.about_us.section_1.create_box.sub_title_en.map(
              (text) => (
                <MobileBoxBlueText>{text}</MobileBoxBlueText>
              )
            )}
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={connectImage} />
            {AppConstants.about_us.section_1.create_box.sub_title_en.map(
              (text) => (
                <MobileBoxBlueText>{text}</MobileBoxBlueText>
              )
            )}
          </Grid>
        </Grid>
      </MobileStyledSection>

      <MobileStyledSection
        id="m-section-2"
        style={{
          backgroundImage: `url(${bg2})`,
        }}
      >
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ marginTop: "100px", color: "rgba(0, 188, 228, 1)" }}
            >
              <strong>{AppConstants.about_us.section_2.title_en[0]}</strong>
            </Typography>
            <Typography variant="h4" style={{ color: "white" }}>
              <strong>{AppConstants.about_us.section_2.title_en[1]}</strong>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={founderLeftImage} />
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={founderCenterImage} />
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={founderRightImage} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MobileStyledP>
            <span style={{ color: "rgba(0, 188, 228, 1)" }}>
              <strong>RDS DIGITAL </strong>
            </span>
            is a team of passionate, professional experts to ensure the
            provision of best-in-class marketing disciplines seamlessly
            integrated and flexibly deployed to meet clients' needs. By
            synergizing talents in different fields, RDS Digital provides
            clients with a comprehensive suite of highly effective digital
            solutions to fulfill clients' business ambition through our unique 3R
            model.
          </MobileStyledP>
          <MobileStyledP>
            Providing holistic digital solutions to magnify clients' digital
            presence and social impacts, we are your trusted partner to navigate
            the digital world.
          </MobileStyledP>
        </Grid>
      </MobileStyledSection>

      <MobileStyledSection
        id="m-section-3"
        style={{
          backgroundImage: `url(${bg3})`,
        }}
      >
        <Grid container justify="center" spacing={1}>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={responsiveImage} />
            <div style={{ marginTop: "20px" }}>
              {AppConstants.about_us.section_3.responsive_box.sub_title_en.map(
                (text) => (
                  <MobileBoxBlueText>{text}</MobileBoxBlueText>
                )
              )}
            </div>
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={reliableImage} />
            <div style={{ marginTop: "20px" }}>
              {AppConstants.about_us.section_3.reliable_box.sub_title_en.map(
                (text) => (
                  <MobileBoxBlueText>{text}</MobileBoxBlueText>
                )
              )}
            </div>
          </Grid>
          <Grid item xs={10}>
            <MobileStyledBoxImage src={relevantImage} />
            <div style={{ marginTop: "20px" }}>
              {AppConstants.about_us.section_3.relevant_box.sub_title_en.map(
                (text) => (
                  <MobileBoxBlueText>{text}</MobileBoxBlueText>
                )
              )}
            </div>
          </Grid>
        </Grid>
      </MobileStyledSection>
      <Footer />
    </>
  );
}

export default MobileAboutUsPage;
