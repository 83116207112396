import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { CategoryType } from "../../types/types";

const StyledUl = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const StyledLi = styled.li`
  color: white;
  font-family: "HelveticaNeueBold";
  padding: 0.2rem 0.4rem;
`;

const selectedButtonStyle = {
  color: "rgba(0, 188, 228, 1)",
  fontSize: "1rem",
  margin: 0,
  padding: 0,
};

const unselectedButtonStyle = {
  color: "white",
  fontSize: "1rem",
  margin: 0,
  padding: 0,
};

interface ShowcaseNavbarProps {
  category: string;
  setCategory: Dispatch<SetStateAction<CategoryType>>;
}

interface ShowcaseNavbarButtonProps {
  category: string;
  setCategory: Dispatch<SetStateAction<CategoryType>>;
  selectedCategory: CategoryType;
  selectedCategoryString: string;
}

function MobileShowcaseNavbarButton({
  category,
  setCategory,
  selectedCategory,
  selectedCategoryString,
}: ShowcaseNavbarButtonProps) {
  return (
    <StyledLi>
      <Button
        variant="text"
        size="large"
        style={
          category === selectedCategory
            ? selectedButtonStyle
            : unselectedButtonStyle
        }
        onClick={() => setCategory(selectedCategory)}
      >
        {selectedCategoryString}
      </Button>
    </StyledLi>
  );
}

function MobileShowcaseNavbar({ category, setCategory }: ShowcaseNavbarProps) {
  return (
    <div style={{ width: "100vw", margin: "2rem auto 1rem" }}>
      <StyledUl>
        <MobileShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="All"
          selectedCategoryString="ALL"
        />
        <MobileShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Holistic_Campaign"
          selectedCategoryString="HOLISTIC CAMPAIGN"
        />
        <MobileShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Video_Production"
          selectedCategoryString="VIDEO"
        />
        <MobileShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="Social"
          selectedCategoryString="SOCIAL"
        />
        <MobileShowcaseNavbarButton
          category={category}
          setCategory={setCategory}
          selectedCategory="China_Solution"
          selectedCategoryString="China Solution"
        />
        <MobileShowcaseNavbarButton
        category={category}
        setCategory={setCategory}
        selectedCategory="Influencer"
        selectedCategoryString="INFLUENCER"
      />
      </StyledUl>
    </div>
  );
}

export default MobileShowcaseNavbar;
